import React from 'react';
import { Redirect } from 'react-router-dom';

// lazy load all the views
const StarterPage = React.lazy(() => import('../pages/StarterPage/index'));

// auth
const Login = React.lazy(() => import('../pages/Auth/Login'));
const Logout = React.lazy(() => import('../pages/Auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/Auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'));
const Register = React.lazy(() => import('../pages/Auth/Register'));
const LockScreen = React.lazy(() => import('../pages/Auth/LockScreen'));
const Profile = React.lazy(() => import('../pages/Profile'));
const ChangePassword = React.lazy(() =>
  import('../pages/Single/ChangePassword')
);
const OTPVerifyScreen = React.lazy(() =>
  import('../pages/Auth/OTPVerifyScreen')
);

const UploadCourse = React.lazy(() => import('../pages/UploadCourse'));
const UploadCourseSummary = React.lazy(() =>
  import('../pages/UploadCourseSummary')
);
const UploadCourseDetail = React.lazy(() =>
  import('../pages/UploadCourseDetail')
);
const ManageLibrary = React.lazy(() => import('../pages/ManageLibrary'));
const ManageLibraryDetail = React.lazy(() =>
  import('../pages/ManageLibraryDetail')
);
const Media = React.lazy(() => import('../pages/Media'));
const CommissionData = React.lazy(() => import('../pages/CommissionData'));
const Library = React.lazy(() => import('../pages/Library'));
const Course = React.lazy(() => import('../pages/Course'));
const CourseDetail = React.lazy(() => import('../pages/Course/Detail'));

// declare all routes
const authProtectedRoutes = [
  { path: '/pages-starter', component: StarterPage },
  { path: '/library', component: Library },
  { path: '/logout', component: Logout },
  { path: '/profile', component: Profile },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/change-password', component: ChangePassword },
  { path: '/manage-library/:id', component: ManageLibraryDetail },
  { path: '/manage-library', component: ManageLibrary },
  { path: '/upload-course/:id/*', component: UploadCourseDetail },
  { path: `/upload-course/:id`, component: UploadCourseSummary },
  { path: `/upload-course/`, component: UploadCourse },
  { path: '/media', component: Media },
  { path: '/commission-data', component: CommissionData },
  { path: '/upload-course/view/:id', component: CourseDetail },
  { path: '/course/:id', component: CourseDetail },
  { path: '/course', component: Course },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/profile" />,
  },
  {
    path: '/profile',
    exact: true,
    component: () => <Redirect to="/profile" />,
  },
  {
    path: '/forget-password',
    exact: true,
    component: () => <Redirect to="/forget-password" />,
  },
  {
    path: '/change-password',
    exact: true,
    component: () => <Redirect to="/change-password" />,
  },
  {
    path: '/upload-course/:id',
    exact: true,
    component: () => <Redirect to="/upload-course/:id" />,
  },
  {
    path: '/upload-course',
    exact: true,
    component: () => <Redirect to="/upload-course" />,
  },
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/lock-screen', component: LockScreen },
  { path: '/otp', component: OTPVerifyScreen },
  { path: '/reset-password', component: ResetPassword },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
