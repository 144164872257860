export const GET_USER_WALLET_REQUEST = 'GET_USER_WALLET_REQUEST';
export const GET_USER_WALLET_SUCCESS = 'GET_USER_WALLET_SUCCESS';
export const GET_USER_WALLET_FAILD = 'GET_USER_WALLET_FAILD';

export const GET_TEACHER_INFO_REQUEST = 'GET_TEACHER_INFO_REQUEST';
export const GET_TEACHER_INFO_SUCCESS = 'GET_TEACHER_INFO_SUCCESS';
export const GET_TEACHER_INFO_FAILED = 'GET_TEACHER_INFO_FAILED';

export const UPDATE_TEACHER_INFO_REQUEST = 'UPDATE_TEACHER_INFO_REQUEST';
export const UPDATE_TEACHER_INFO_SUCCESS = 'UPDATE_TEACHER_INFO_SUCCESS';
export const UPDATE_TEACHER_INFO_FAILED = 'UPDATE_TEACHER_INFO_FAILED';

export const UPDATE_TEACHER_NAME_REQUEST = 'UPDATE_TEACHER_NAME_REQUEST';
export const UPDATE_TEACHER_NAME_SUCCESS = 'UPDATE_TEACHER_NAME_SUCCESS';
export const UPDATE_TEACHER_NAME_FAILED = 'UPDATE_TEACHER_NAME_FAILED';
