import {
  GET_LIST_MEDIA_SUCCESS,
  GET_LIST_MEDIA_REQUEST,
  GET_LIST_MEDIA_FAILED,
  CREATE_MEDIA_SUCCESS,
  CREATE_MEDIA_FAILED,
  CREATE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
} from './constant';

const INIT_STATE = {
  medias: [],
  currentMedia: null,
  last_page: 0,
  processing: false,
  hasError: false,
  errorMessage: '',
  uploaded: false,
};

const Media = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_MEDIA_REQUEST: {
      return {
        ...state,
        processing: true,
        uploaded: false,
      };
    }
    case GET_LIST_MEDIA_SUCCESS: {
      const { current_page, data, last_page } = action.payload;
      return {
        ...state,
        medias: current_page === 1 ? data : [...state.medias, ...data],
        last_page: last_page,
        processing: false,
        loading_more: false,
        uploaded: false,
      };
    }
    case GET_LIST_MEDIA_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        uploaded: false,
      };
    }

    case CREATE_MEDIA_REQUEST: {
      return {
        ...state,
        processing: true,
        uploaded: false,
      };
    }

    case CREATE_MEDIA_SUCCESS: {
      return {
        ...state,
        currentMedia: action.payload,
        processing: false,
        uploaded: true,
      };
    }
    case CREATE_MEDIA_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case DELETE_MEDIA_REQUEST: {
      return {
        processing: true,
      };
    }

    case DELETE_MEDIA_SUCCESS: {
      return {
        ...state,
        currentMedia: action.payload,
        processing: false,
      };
    }
    case DELETE_MEDIA_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default Media;
