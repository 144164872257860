import {
  GET_LIST_COURSE_SUCCESS,
  GET_LIST_COURSE_REQUEST,
  GET_LIST_COURSE_FAILED,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILED,
  UPDATE_CURRENT_COURSE,
  PREPARE_CREATE_COURSE,
  CREATE_COURSE_REQUEST,
  GET_LIST_SECTION_SUCCESS,
  GET_LIST_SECTION_REQUEST,
  UPDATE_CURRENT_SECTION,
  VALIDATE_FAILED,
  VALIDATE_SUCCESS,
  FINNISH_CREATE,
  CREATE_LECTURE_REQUEST,
  CREATE_LECTURE_SUCCESS,
  CREATE_LECTURE_FAILED,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAILED,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILED,
  RESET_PROCESS_STATE,
  BEGIN_SAVEDRAFT,
  END_SAVEDRAFT,
  BEGIN_SUBMIT,
  END_SUBMIT,
  DELETE_SECTION_FAILED,
  VIEW_DETAIL,
  GET_LIST_LECTURE_SUCCESS,
  UPDATE_LECTURE_FAILED,
  UPDATE_LECTURE_REQUEST,
  UPDATE_LECTURE_SUCCESS
} from './constant';

const INIT_STATE = {
  courses: [],
  sections: null,
  lectures: [],
  currentCourse: null,
  last_page: 0,
  processing: false,
  processCreating: false,
  loading_more: false,
  hasError: false,
  isEdit: false,
  errorMessage: '',
  isSaveFinish: false,
  processSaveDraft: false,
  processSubmit: false,
  isGetNewCourse: false,
  isViewDetail: false,
};

const Course = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_COURSE_REQUEST: {
      return {
        ...state,
        processing: true,
        isViewDetail: false,
        isEdit: false,
      };
    }
    case GET_LIST_COURSE_SUCCESS: {
      const { current_page, data, last_page } = action.payload;
      return {
        ...state,
        courses: current_page === 1 ? data : [...state.courses, ...data],
        last_page: last_page,
        processing: false,
        loading_more: false,
      };
    }
    case GET_LIST_COURSE_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
      };
    }
    case PREPARE_CREATE_COURSE: {
      return {
        ...state,
        currentCourse: null,
        sections: null,
        isEdit: false,
        hasError: false,
      };
    }

    case CREATE_COURSE_REQUEST: {
      return {
        processing: true,
        processCreating: true,
      };
    }

    case CREATE_COURSE_SUCCESS: {
      return {
        ...state,
        currentCourse: action.payload,
        processing: false,
      };
    }
    case CREATE_COURSE_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        processSaveDraft: false,
        processSubmit: false,
        errorMessage: action.payload,
      };
    }

    case UPDATE_COURSE_REQUEST: {
      return {
        ...state,
        processing: true,
        processCreating: true,
        isSaveFinish: false,
        processSaveDraft: true,
      };
    }

    case UPDATE_COURSE_SUCCESS: {
      return {
        ...state,
        currentCourse: action.payload,
        processing: false,
        isSaveFinish: true,
        processSaveDraft: false,
      };
    }
    case UPDATE_COURSE_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
        isSaveFinish: true,
      };
    }

    case CREATE_SECTION_REQUEST: {
      return {
        processing: true,
      };
    }

    case CREATE_SECTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case CREATE_SECTION_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case CREATE_LECTURE_REQUEST: {
      return {
        processing: true,
      };
    }

    case CREATE_LECTURE_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case CREATE_LECTURE_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }
    case UPDATE_LECTURE_REQUEST: {
      return {
        processing: true,
      };
    }

    case UPDATE_LECTURE_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case UPDATE_LECTURE_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case UPDATE_CURRENT_COURSE: {
      const { currentCourse, isEdit, isViewDetail } = action.payload;
      return {
        ...state,
        currentCourse: currentCourse,
        isEdit: isEdit,
        hasError: false,
        isViewDetail: isViewDetail,
      };
    }

    case GET_LIST_SECTION_REQUEST: {
      return {
        ...state,
        processing: true,
      };
    }
    case GET_LIST_SECTION_SUCCESS: {
      return {
        ...state,
        sections: action.payload,
        processing: false,
        loading_more: false,
      };
    }

    case UPDATE_CURRENT_SECTION: {
      const currentSection = action.payload;
      return {
        ...state,
        sections: currentSection,
      };
    }

    case VALIDATE_FAILED: {
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case VALIDATE_SUCCESS: {
      return {
        ...state,
        hasError: false,
        errorMessage: '',
      };
    }

    case FINNISH_CREATE: {
      return {
        ...state,
        processCreating: false,
      };
    }

    case DELETE_SECTION_FAILED: {
      return {
        ...state,
        processCreating: false,
        processSaveDraft: false,
        processSubmit: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case BEGIN_SAVEDRAFT: {
      return {
        ...state,
        hasError: false,
        processSaveDraft: true,
      };
    }

    case END_SAVEDRAFT: {
      return {
        ...state,
        processSaveDraft: false,
      };
    }

    case BEGIN_SUBMIT: {
      return {
        ...state,
        hasError: false,
        processSubmit: true,
      };
    }

    case END_SUBMIT: {
      return {
        ...state,
        processSubmit: false,
      };
    }

    case RESET_PROCESS_STATE: {
      return {
        ...state,
        processCreating: false,
        isSaveFinish: false,
        processSaveDraft: false,
        processSubmit: false,
        process: false,
        hasError: false,
        loading_more: false,
      };
    }

    case VIEW_DETAIL: {
      return {
        ...state,
        isViewDetail: true,
      };
    }

    case GET_LIST_LECTURE_SUCCESS: {
      return {
        ...state,
      };
    }

    default:
      return { ...state };
  }
};

export default Course;
