export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILD = 'GET_NOTIFICATIONS_FAILD';

export const OPEN_NOTIFICATIONS = 'OPEN_NOTIFICATIONS';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';

export const GET_UNREAD_NOTIFICATION_REQUEST =
  'GET_UNREAD_NOTIFICATION_REQUEST';
export const GET_UNREAD_NOTIFICATION_SUCCESS =
  'GET_UNREAD_NOTIFICATION_SUCCESS';
export const GET_UNREAD_NOTIFICATION_FAILD = 'GET_UNREAD_NOTIFICATION_FAILD';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILD = 'UPDATE_U_NOTIFICATION_FAILD';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const MARK_READ_NOTIFICATION_SUCCESS = 'MARK_READ_NOTIFICATION_SUCCESS';
