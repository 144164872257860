import {
  GET_GENERAL_DATA_REQUEST,
  GET_GENERAL_DATA_SUCCESS,
  GET_GENERAL_DATA_FAILD,
  SET_INITILIZED_DATA,
} from './constants';

export const getGeneralDataRequest = (payload) => ({
  type: GET_GENERAL_DATA_REQUEST,
  payload,
});

export const getGeneralDataSuccess = (payload) => ({
  type: GET_GENERAL_DATA_SUCCESS,
  payload,
});

export const getGeneralDataFaild = () => ({
  type: GET_GENERAL_DATA_FAILD,
});

export const setInitializedData = (payload) => ({
  type: SET_INITILIZED_DATA,
  payload: payload
});
