export const PROFILEFLOW = 'profile';
export const MANAGELIBRARYFLOW = 'manage-library';
export const COURSEFLOW = 'upload-course';
export const MEDIAFLOW = 'media';
export const COMMISONDATAFLOW = 'commission-data';

export const PROFILE = 'Profile';
export const ONLINECOURSE = 'Online course';
export const CREATECOURSE = 'Create new course';
export const EDITCOURSE = 'Edit course';
export const COURSEDETAIL = 'Course detail';
export const MANAGELIBRARY = 'Manage library';
export const MANAGELIBRARY_ADD = 'New  Post';
export const MANAGELIBRARY_EDIT = 'Edit post';
export const MEDIA = 'Media';
export const COMMISONDATA = 'Commission data';

export const profileLinkName = [PROFILE];
export const courseLinkName = [ONLINECOURSE, CREATECOURSE, COURSEDETAIL];
export const libraryLinkName = [MANAGELIBRARY, MANAGELIBRARY_ADD];
export const mediaLinkName = [MEDIA];
export const commissionDataLinkName = [COMMISONDATA];
