import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { setActiveTab } from '../../redux/actions';

//Import Images
import NotificationList from 'src/pages/Notification';
import {
  closeNotifications,
  // openNotifications,
} from 'src/redux/notifications/actions';
import Logo from 'src/components/Logo';
import MenuItem from 'src/components/MenuItem';

//i18n

function LeftSidebarMenu(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = history.location.pathname || props.activeTab;

  const handleClick = (page) => {
    toggleTab(page);
    history.push(page);
  };

  const handlecloseModalNoti = () => {
    dispatch(closeNotifications());
  };

  // const handlecOPenModalNoti = () => {
  //   dispatch(openNotifications());
  // };

  const showNotiList = useMemo(() => <NotificationList />, []);

  return (
    <React.Fragment>
      {showNotiList}
      <div className="aside">
        <Logo />
        <div className="menu">
          <MenuItem
            onClick={() => {
              handleClick('/profile');
              handlecloseModalNoti();
            }}
            classNames={classnames('menu-item', {
              active: activeTab.includes('profile'),
            })}
            title={'profile'}
          />
          <MenuItem
            onClick={() => {
              handleClick('/manage-library');
              handlecloseModalNoti();
            }}
            classNames={classnames('menu-item', {
              active: activeTab.includes('manage-library'),
            })}
            title={'manage library'}
          />
          <MenuItem
            onClick={() => {
              handleClick('/upload-course');
              handlecloseModalNoti();
            }}
            classNames={classnames('menu-item', {
              active: activeTab.includes('upload-course'),
            })}
            title={'upload course'}
          />
          <MenuItem
            onClick={() => {
              handleClick('/media');
              handlecloseModalNoti();
            }}
            classNames={classnames('menu-item', {
              active: activeTab.includes('media'),
            })}
            title={'media'}
          />
          <MenuItem
            onClick={() => {
              handleClick('/commission-data');
              handlecloseModalNoti();
            }}
            classNames={classnames('menu-item', {
              active: activeTab.includes('commission-data'),
            })}
            title={'commission data'}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { user } = state.Auth;
  return {
    ...state.Layout,
    user,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
