import {
  GET_LIST_TRANSACTION_SUCCESS,
  GET_LIST_TRANSACTION_REQUEST,
  GET_LIST_TRANSACTION_FAILED,
  FILTER,
  RESET_FILTER,
} from './constant';

const INIT_STATE = {
  transactions: [],
  currentTransaction: null,
  last_page: 0,
  processing: false,
  hasError: false,
  errorMessage: '',
  startSearch: false,
  finishSearch: false,
  total_commission: 0,
  total_revenue: 0,
  filterParams: {
    course: null,
    dateFrom: null,
    dateTo: null,
  },
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_TRANSACTION_REQUEST: {
      return {
        ...state,
        processing: true,
        uploaded: false,
      };
    }
    case GET_LIST_TRANSACTION_SUCCESS: {
      const { current_page, data, last_page, total_commission, total_revenue } =
        action.payload;

      return {
        ...state,
        transactions:
          current_page === 1 ? data : [...state.transactions, ...data],
        last_page: last_page,
        processing: false,
        loading_more: false,
        total_commission: total_commission,
        total_revenue: total_revenue,
        startSearch: false,
        finishSearch: false,
      };
    }
    case GET_LIST_TRANSACTION_FAILED: {
      return {
        ...state,
        processing: false,
        loading_more: false,
      };
    }

    case FILTER: {
      return {
        ...state,
        filterParams: action.payload,
        startSearch: true,
      };
    }

    case RESET_FILTER: {
      return {
        ...state,
        filterParam: action.payload,
        finishSearch: true,
      };
    }

    default:
      return { ...state };
  }
};

export default Transaction;
