import axios from 'axios';
import config from './../config';
import { getLocalStorage } from '.';
import { LANGUAGE } from 'src/pages/Library/constants';

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // const status = error?.response?.status;
    // switch (status) {
    //   case 500:
    //     message = 'Internal Server Error';
    //     break;
    //   case 401:
    //     message = 'Invalid credentials';
    //     break;
    //   case 404:
    //     message = 'Sorry! the data you are looking for could not be found';
    //     break;
    //   case 400:
    //   case 422:
    //     message = error.response;
    //     break;
    //   default:
    //     message = error.message || error;
    // }
    let message = getErrorMessage(
      error.response?.data,
      error.response?.config?.url
    );
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAcceptLanguage = () => {
  const language =
    getLocalStorage(config.STORAGE_KEY_USER)?.user?.language || LANGUAGE.EN;
  axios.defaults.headers.common['Accept-Language'] = language;
};

const setAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  setAcceptLanguage();
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    setAcceptLanguage();
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data, options = undefined) => {
    setAcceptLanguage();
    if (options !== undefined) {
      return axios.post(url, data, options);
    }
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = async (url, data) => {
    setAcceptLanguage();
    const result = await axios.put(url, data);
    return result;
  };

  /**
   * Updates data
   */
  updateViaPost = async (url, data) => {
    setAcceptLanguage();
    const result = await axios.post(url, data);
    return result;
  };

  /**
   * Delete put
   */
  deletePut = (url) => {
    setAcceptLanguage();
    return axios.put(url);
  };

  delete = (url) => {
    setAcceptLanguage();
    return axios.delete(url);
  };
}

const getErrorMessage = function (response, domainApi = '') {
  if (response?.success === false) {
    const urlGetMessgeError = ['/login'].some((x) => domainApi.includes(x));

    if (urlGetMessgeError && response.message) {
      return response.message;
    }

    if (response.data && response.data.error) {
      const error = response.data.error;

      if (typeof error === 'string') {
        return error;
      }

      const errorFields = Object.keys(error);

      if (errorFields.length > 0) {
        const firstErrorField = errorFields[0];
        const errorMessages = error[firstErrorField];

        if (errorMessages.length > 0) {
          const firstErrorMessage = errorMessages[0];
          return firstErrorMessage;
        }
      }
    }

    if (response.message) {
      return response.message;
    }
  }
  return '';
};

export { APIClient, setAuthorization };
