import {
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_SUCCESS,
  GET_USER_WALLET_FAILD,
  GET_TEACHER_INFO_REQUEST,
  GET_TEACHER_INFO_SUCCESS,
  GET_TEACHER_INFO_FAILED,
  UPDATE_TEACHER_INFO_REQUEST,
  UPDATE_TEACHER_INFO_SUCCESS,
  UPDATE_TEACHER_INFO_FAILED,
  UPDATE_TEACHER_NAME_REQUEST,
  UPDATE_TEACHER_NAME_SUCCESS,
  UPDATE_TEACHER_NAME_FAILED,
} from './constants';

export const getUserWalletRequest = (payload) => {
  return {
    type: GET_USER_WALLET_REQUEST,
    payload,
  };
};

export const getUserWalletSuccess = (payload) => ({
  type: GET_USER_WALLET_SUCCESS,
  payload,
});

export const getUserWalletFaild = () => ({
  type: GET_USER_WALLET_FAILD,
});

export const getTeacherInfoRequest = (payload) => ({
  type: GET_TEACHER_INFO_REQUEST,
  payload,
});

export const getTeacherInfoSuccess = (payload) => ({
  type: GET_TEACHER_INFO_SUCCESS,
  payload,
});

export const getTeacherInfoFailed = (payload) => ({
  type: GET_TEACHER_INFO_FAILED,
  payload,
});

export const updateTeacherInfoRequest = (payload) => ({
  type: UPDATE_TEACHER_INFO_REQUEST,
  payload,
});

export const updateTeacherInfoSuccess = (payload) => ({
  type: UPDATE_TEACHER_INFO_SUCCESS,
  payload,
});

export const updateTeacherInfoFailed = (payload) => ({
  type: UPDATE_TEACHER_INFO_FAILED,
  payload,
});

export const updateTeacherNameRequest = (payload) => ({
  type: UPDATE_TEACHER_NAME_REQUEST,
  payload,
});

export const updateTeacherNameSuccess = (payload) => ({
  type: UPDATE_TEACHER_NAME_SUCCESS,
  payload,
});

export const updateTeacherNameFailed = (payload) => ({
  type: UPDATE_TEACHER_NAME_FAILED,
  payload,
});
