import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import './assets/remix-icons/remixicon.css';
import './assets/css/main.css';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { ToastContainer } from 'react-toastify';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

ReactDOM.render(
  <Provider store={configureStore()}>
    <I18nextProvider i18n={i18n}>
      <ToastContainer position="top-right" theme="colored" />
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
