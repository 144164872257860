import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getNotificationsRequest,
  getUnreadNotificationRequest,
  openNotifications,
  updateNotificationRequest,
  getMarkAllReadNotificationSuccess,
} from 'src/redux/notifications/actions';

import CardNoti from './components/Card';
import { NOTI_STATUS } from './constants';
import EmptyData from 'src/components/EmptyData';
import { formatDateTime } from 'src/helpers/formatTime';
import { notificationService } from 'src/services/notificationService';
import { NOTIFICATION_TYPES } from 'src/constants';

const NotificationList = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const data = useSelector((state) => state.Notifications);
  const auth = useSelector((state) => state.Auth.user);
  // const unreadNoti = useSelector(
  //   (state) => state.Notifications.unreadNotification
  // );
  const { notifications, last_page, openPopup } = data;

  useEffect(() => {
    if (!auth?.user?.id || page === 1) return;
    dispatch(getNotificationsRequest({ subscriber_id: auth.user.id, page }));
    // eslint-disable-next-line
  }, [dispatch, auth?.user?.id, page]);

  const handleGetNoti = () => {
    if (page >= last_page) return;
    setTimeout(() => {
      setPage((prev) => prev + 1);
    }, 1000);
  };

  useEffect(() => {
    setIsOpen(openPopup);
  }, [openPopup]);

  const handleCloseModal = () => {
    dispatch(openNotifications());
  };

  const goToPageDetail = (type, entityId, status, idNoti) => {
    if (status !== NOTI_STATUS.READ) {
      dispatch(
        updateNotificationRequest({ status: 'READ', id: idNoti }, () => {
          dispatch(getUnreadNotificationRequest());
          dispatch(
            getNotificationsRequest({ subscriber_id: auth.user.id, page: 1 })
          );
        })
      );
    }
    handleCloseModal();
    switch (type) {
      case NOTIFICATION_TYPES.NEW_QUESTION:
        history.push('/library/' + entityId);
        break;
      case NOTIFICATION_TYPES.POST_COMMENT:
      case NOTIFICATION_TYPES.POST_LIKE:
        history.push('/community/' + entityId);
        break;
      default:
        break;
    }
  };

  const getMarkAllRead = async () => {
    try {
      let response = await notificationService.markAsRead();
      if (response) {
        dispatch(getMarkAllReadNotificationSuccess());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const groupedNotis = notifications.reduce((groups, noti) => {
    const created_at = formatDateTime(noti.created_at);
    if (groups[created_at]) {
      groups[created_at].push(noti);
    } else {
      groups[created_at] = [noti];
    }
    return groups;
  }, {});

  const sortedDates = Object.keys(groupedNotis).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateB - dateA;
  });

  function checkDate(dateString) {
    const currentDate = new Date();
    const [date, month, year] = dateString.split('-');
    const inputDate = new Date(`${month}-${date}-${year}`);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const inputYear = inputDate.getFullYear();
    const inputMonth = inputDate.getMonth();
    const inputDay = inputDate.getDate();

    if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay === inputDay
    ) {
      return 'Today';
    } else if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay - inputDay === 1
    ) {
      return 'Yesterday';
    }

    return dateString;
  }

  return (
    <Modal
      isOpen={isOpen}
      className={`notifications-wrapper ${isOpen ? 'back-in-left' : ''}`}
      toggle={handleCloseModal}
      backdropClassName="backdrop-notification"
      modalClassName="overflow-noti"
      wrapClassName="wrapNotis"
    >
      <div className="notifications">
        <div className="top_notification d-flex">
          <div className="top_title_noti">
            <h4>{t('sidebar.notification').toLocaleUpperCase()}</h4>
          </div>
          <div className="mark_all_read d-flex align-items-center">
            <span onClick={() => getMarkAllRead()}>{t('mark_read')}</span>
          </div>
        </div>
        <div className="notifications_card_list" id="scrollableDiv">
          <InfiniteScroll
            dataLength={notifications.length}
            next={handleGetNoti}
            hasMore={true}
            loader={page >= last_page ? null : <h6>{t('loading')}...</h6>}
            scrollableTarget="scrollableDiv"
          >
            {!notifications.length && (
              <EmptyData title={t('no_notification')} />
            )}

            {notifications &&
              sortedDates.map((date) => (
                <div key={date}>
                  <h5 className="title_date_group">{checkDate(date)}</h5>
                  {groupedNotis[date].map((noti, index) => (
                    <CardNoti
                      key={noti.id}
                      noti={noti}
                      goToPageDetail={goToPageDetail}
                    />
                  ))}
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
}, []);

export default NotificationList;
