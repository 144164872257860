import React from 'react';

const InputIcon = ({
  value,
  onChange,
  placeholder,
  icon,
  clear,
  onClick,
  onClear,
  ...props
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="input-icon" onClick={onClick}>
      {icon}
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        {...props}
      />
      {clear && value && (
        <i className="ri-close-circle-fill" onClick={onClear}></i>
      )}
    </div>
  );
};

export default InputIcon;
