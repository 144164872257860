import {
  CREATE_ANSWER_FAILED,
  CREATE_ANSWER_REQUEST,
  CREATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILED,
  UPDATE_ANSWER_REQUEST,
  UPDATE_ANSWER_SUCCESS,
} from './constants';

const INIT_STATE = {
  answer: null,
  last_page: 0,
  answerProcessing: false,
  loading_more: false,
  hasError: false,
  errorMessage: '',
};

const Answer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_ANSWER_REQUEST: {
      return {
        ...state,
        answerProcessing: true,
        hasError: false,
      };
    }

    case CREATE_ANSWER_SUCCESS: {
      return {
        ...state,
        answer: action.payload,
        answerProcessing: false,
      };
    }

    case CREATE_ANSWER_FAILED: {
      return {
        ...state,
        answerProcessing: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case UPDATE_ANSWER_REQUEST: {
      return {
        ...state,
        answerProcessing: true,
        hasError: false,
      };
    }

    case UPDATE_ANSWER_SUCCESS: {
      return {
        ...state,
        answer: action.payload,
        answerProcessing: false,
      };
    }

    case UPDATE_ANSWER_FAILED: {
      return {
        ...state,
        answerProcessing: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default Answer;
