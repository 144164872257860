export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILD = 'GET_QUESTIONS_FAILD';

export const GET_QUESTIONS_DETAIL_REQUEST = 'GET_QUESTIONS_DETAIL_REQUEST';
export const GET_QUESTIONS_DETAIL_SUCCESS = 'GET_QUESTIONS_DETAIL_SUCCESS';
export const GET_QUESTIONS_DETAIL_FAILD = 'GET_QUESTIONS_DETAIL_FAILD';

export const FILTER_QUESTIONS_REQUEST = 'FILTER_QUESTIONS_REQUEST';
export const FILTER_QUESTIONS_SUCCESS = 'FILTER_QUESTIONS_SUCCESS';
export const FILTER_QUESTIONS_FAILED = 'FILTER_QUESTIONS_FAILED';

export const QUESTIONS_UNLOCK_REQUEST = 'QUESTIONS_UNLOCK_REQUEST';
export const QUESTIONS_UNLOCK_SUCCESS = 'QUESTIONS_UNLOCK_SUCCESS';
export const QUESTIONS_UNLOCK_FAILD = 'QUESTIONS_UNLOCK_FAILD';

export const QUESTIONS_FAVORITE_REQUEST = 'QUESTIONS_FAVORITE_REQUEST';
export const QUESTIONS_FAVORITE_SUCCESS = 'QUESTIONS_FAVORITE_SUCCESS';
export const QUESTIONS_FAVORITE_FAILD = 'QUESTIONS_FAVORITE_FAILD';

export const CREATE_QUESTION_REQUEST = 'CREATE_QUESTION_REQUEST';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAILED = 'CREATE_QUESTION_FAILED';

export const UPDATE_QUESTION_REQUEST = 'UPDATE_QUESTION_REQUEST';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILED = 'UPDATE_QUESTION_FAILED';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED';

export const CREATE_QUESTION_FINISH = 'CREATE_QUESTION_FINISH';
export const UPDATE_QUESTION_FINISH = 'UPDATE_QUESTION_FINISH';

export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const CHANGE_KEYWORD = 'CHANGE_KEYWORD';
export const SET_PARAM = 'SET_PARAM';
