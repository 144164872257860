import { all, call, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_UNREAD_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_REQUEST,
} from './constants';

import * as actions from './actions';

const get = new APIClient().get;
const update = new APIClient().update;

function* getNotificationsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/notifications', {
      params: payload,
    });

    yield put(actions.getNotificationsSuccess(response.data));
  } catch (error) {}
}

function* getUnreadNotificationRequest() {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/users/unread-notification');
    yield put(actions.getUnreadNotificationSuccess(response.data));
  } catch (error) {}
}

function* updateNotificationRequest({ payload, callback }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, `api/notifications/${payload.id}`, {
      data: { status: payload.status },
    });

    callback();

    yield put(actions.getNotificationsSuccess(response.data));
  } catch (error) {}
}

export default function* notificationsSaga() {
  yield all([
    takeEvery(GET_NOTIFICATIONS_REQUEST, getNotificationsRequest),
    takeEvery(GET_UNREAD_NOTIFICATION_REQUEST, getUnreadNotificationRequest),
    takeEvery(UPDATE_NOTIFICATION_REQUEST, updateNotificationRequest),
  ]);
}
