import { all, call, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import { CREATE_ANSWER_REQUEST, UPDATE_ANSWER_REQUEST } from './constants';

import * as actions from './actions';

const post = new APIClient().create;

function* createAnswersRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(post, 'api/answers', payload);
    yield put(actions.createAnswerSuccess(response.data));
  } catch (error) {
    actions.createAnswerFailed(error);
  }
}

function* updateAnswersRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    let result = null;
    if (payload.id) {
      const response = yield post(
        `api/answers/${payload.id}?_method=PUT`,
        payload.formData
      );

      result = response.date;
    } else {
      result = [];
    }
    yield put(actions.updateAnswerSuccess(result));
  } catch (error) {
    actions.updateAnswerFailed(error);
  }
}

export default function* answerSaga() {
  yield all([
    takeEvery(CREATE_ANSWER_REQUEST, createAnswersRequest),
    takeEvery(UPDATE_ANSWER_REQUEST, updateAnswersRequest),
  ]);
}
