import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { getAccessToken } from 'src/helpers/user';

const apiClient = new APIClient();
const mediaService = {
  getMedias: (page) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/medias?page=${page}`);
  },
  getMedia: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/medias/${id}`);
  },
  createMedia: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/medias`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteMeia: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/medias`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
export { mediaService };
