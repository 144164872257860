import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_LIST_MEDIA_REQUEST,
  CREATE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST,
} from './constant';
import {
  getMediasSuccess,
  createMediaSuccess,
  createMediaFaild,
  getMediasFaild,
  deleteMediaSuccess,
  deleteMediaFaild,
} from './action';
import { mediaService } from 'src/services/mediaService';

function* getMediasRequest(action) {
  try {
    const response = yield call(mediaService.getMedias, action.payload);
    yield put(getMediasSuccess(response.data));
  } catch (error) {
    yield put(getMediasFaild(error));
  }
}

function* createMediaRequest(action) {
  try {
    const formData = new FormData();
    formData.append('image', action.payload);
    const mediaResponse = yield call(mediaService.createMedia, formData);
    yield put(createMediaSuccess(mediaResponse.data));
  } catch (error) {
    yield put(createMediaFaild(error));
  }
}

function* deleteMediaRequest(action) {
  try {
    const mediaResponse = yield call(mediaService.createMedia, action.payload);
    yield put(deleteMediaSuccess(mediaResponse.data));
  } catch (error) {
    yield put(deleteMediaFaild(error));
  }
}

export function* watchGetMediasRequest() {
  yield takeEvery(GET_LIST_MEDIA_REQUEST, getMediasRequest);
}

export function* watchCeateMediaRequest() {
  yield takeEvery(CREATE_MEDIA_REQUEST, createMediaRequest);
}

export function* watchDeleteMediaRequest() {
  yield takeEvery(DELETE_MEDIA_REQUEST, deleteMediaRequest);
}

export default function* mediaSaga() {
  yield all([
    fork(watchCeateMediaRequest),
    fork(deleteMediaRequest),
    fork(watchGetMediasRequest),
  ]);
}
