import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './layout.scss';

//Import Components
import LeftSidebarMenu from './LeftSidebarMenu';
import PopupKickout from 'src/components/PopupKickout';
import { listenWebsocketEvent } from 'src/services/websocket';
import { logoutUser } from '../../redux/actions';
import {
  getGeneralDataRequest,
  setInitializedData,
} from 'src/redux/masterData/actions';
import {
  getUnreadNotificationRequest,
  getNotificationsRequest,
  addNotifications,
} from 'src/redux/notifications/actions';
import { connect } from 'react-redux';
import { userService } from 'src/services/userService';
import Loading from 'src/components/Loading';
import RightPanel from './RightPanel';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKickedOut: false,
      isLoading: true,
    };
    this.capitalizeFirstLetter.bind(this);
  }

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  registerWebsocket = () => {
    if (this.props.currentUser && this.props.currentUser.user) {
      listenWebsocketEvent(this.props.currentUser.user.id, {
        NewMessageEvent: (value) => {
          this.props.addMessage(value);
          this.props.fetchingMessageSuccess();
        },
        KickOutEvent: async () => {
          let logged = await userService.verifyToken();
          if (!logged) {
            this.setState({
              isKickedOut: true,
            });
          }
        },
        AddNotifications: (value) => {
          this.props.addNotifications(value);
        },
      });
    }
  };

  initFetchData() {
    this.props.getGeneralDataRequest();
    this.props.getUnreadNotificationRequest();
    this.props.getNotificationsRequest();
  }

  async componentDidMount() {
    if (!this.props.isDataFetched) {
      let logged = await userService.verifyToken();
      if (!logged) {
        this.props.logoutUser(this.props.history);
      }
      this.initFetchData();
      userService.log(this.props.currentUser?.user?.id);
      this.props.setInitializedData(true);
    }
    this.setState({
      isLoading: false,
    });
    //set document title according to page path name
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
    document.title = currentage + ' | Pombeebee';
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div style={{ marginTop: '25%' }}>
          <Loading />
        </div>
      );
    }
    return (
      <React.Fragment>
        <PopupKickout isOpen={false}></PopupKickout>
        <div className="layout-teacher">
          {/* left sidebar menu */}
          <LeftSidebarMenu />
          <RightPanel>{this.props.children}</RightPanel>
          {/* render page content */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.Auth.user;
  const isDataFetched = state.GeneralData?.initializedData;
  return { currentUser, isDataFetched };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    getGeneralDataRequest,
    setInitializedData,
    getUnreadNotificationRequest,
    getNotificationsRequest,
    addNotifications,
  })(Index)
);
