import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducer';
import Users from './users/reducer';
import Questions from './questions/reducer';
import Notifications from './notifications/reducer';
import GeneralData from './masterData/reducer';
import Course from './course/reducer';
import Media from './media/reducer';
import Answer from './answer/reducer';
import Transaction from './mission-data/reducer';
import CourseDetail from './course-detail/reducer';

export default combineReducers({
  Auth,
  Users,
  Course,
  Layout,
  Questions,
  Notifications,
  GeneralData,
  Media,
  Answer,
  Transaction,
  CourseDetail,
});
