import {
  GET_LIST_TRANSACTION_SUCCESS,
  GET_LIST_TRANSACTION_REQUEST,
  GET_LIST_TRANSACTION_FAILED,
  FILTER,
  RESET_FILTER,
} from './constant';

//TRANSACTION
export const getTransactionsRequest = (payload) => ({
  type: GET_LIST_TRANSACTION_REQUEST,
  payload,
});

export const getTransactionsSuccess = (payload) => ({
  type: GET_LIST_TRANSACTION_SUCCESS,
  payload,
});

export const getTransactionsFaild = (payload) => ({
  type: GET_LIST_TRANSACTION_FAILED,
  payload,
});

export const filterTraction = (payload) => ({
  type: FILTER,
  payload,
});

export const resetFilterTraction = () => ({
  type: RESET_FILTER,
});
