export const FETCH_COURSE = 'FETCH_COURSE';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_DETAIL = 'FETCH_COURSE_DETAIL';
export const FETCH_COURSE_DETAIL_SUCCESS = 'FETCH_COURSE_DETAIL_SUCCESS';
export const FETCH_COURSE_LECTURE = 'FETCH_COURSE_LECTURE';
export const FETCH_COURSE_LECTURE_SUCCESS = 'FETCH_COURSE_LECTURE_SUCCESS';
export const FETCH_LECTURE_SUCCESS = 'FETCH_LECTURE_SUCCESS';
export const FETCH_LECTURE = 'FETCH_LECTURE';
export const FETCH_COURSE_SECTIONS = 'FETCH_COURSE_SECTIONS';
export const FETCH_COURSE_SECTIONS_SUCCESS = 'FETCH_COURSE_SECTIONS_SUCCESS';
export const BUY_COURSE = 'BUY_COURSE';
export const BUY_COURSE_SUCCESS = 'BUY_COURSE_SUCCESS';
export const CREATE_RATE_COURSE = 'CREATE_RATE_COURSE';
export const CREATE_RATE_COURSE_SUCCESS = 'CREATE_RATE_COURSE_SUCCESS';
export const FETCH_RATE = 'FETCH_RATE';
export const FETCH_RATE_SUCCESS = 'FETCH_RATE_SUCCESS';
export const FETCH_TEACHER = 'FETCH_TEACHER';
export const FETCH_TEACHER_SUCCESS = 'FETCH_TEACHER_SUCCESS';
export const CREATE_REPLY_RATE = 'CREATE_REPLY_RATE';
export const CREATE_REPLY_RATE_SUCCESS = 'CREATE_REPLY_RATE_SUCCESS';
export const RESET_RATINGS = 'RESET_RATINGS';
