import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import Button from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  prepareCreateCourse,
  validateRequestSuccess,
} from 'src/redux/course/action';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import {
  MANAGELIBRARYFLOW,
  MEDIAFLOW,
  PROFILEFLOW,
  COURSEFLOW,
  EDITCOURSE,
  courseLinkName,
  mediaLinkName,
  commissionDataLinkName,
  libraryLinkName,
  COMMISONDATAFLOW,
  CREATECOURSE,
  MANAGELIBRARY_EDIT,
  MANAGELIBRARY_ADD,
} from '../Breadcrumb/constant';
import ModalUploadMedia from '../ModalUploadMedia';
import Search from '../Search';
import CommissionInfo from '../CommissionInfo';
import { createMediaRequest } from 'src/redux/media/action';
import { searchKeywordOnChange } from 'src/redux/questions/actions';
import { toast } from 'react-toastify';

const PageHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [breadcrumNames, setBreadcrumNames] = useState([]);
  const { isEdit, isViewDetail, currentCourse } = useSelector(
    (state) => state.Course
  );
  const { question } = useSelector((state) => state.Questions);
  const [title, setTitle] = useState('');
  const [showPageHeader, setShowPageHeader] = useState(false);
  const [isCommission, setIsCommission] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showBottomAction, setShowBottomAction] = useState(false);
  const [showPopupCreateMedia, setShowPopupCreateMedia] = useState(false);
  const [libararySearchValue, setLibararySearchValue] = useState('');
  const history = useHistory();
  const { processing, uploaded } = useSelector((state) => state.Media);
  const links = location.pathname.split('/').filter((link) => link !== '');

  useEffect(() => {
    let newBreadcrumbNames = [...breadcrumNames];
    setShowPageHeader(true);
    setIsCommission(false);
    switch (links[0]) {
      case COURSEFLOW:
        let newCourseLinkNames = [...courseLinkName];
        if (isEdit) {
          newCourseLinkNames[1] = EDITCOURSE;
        } else if (isViewDetail) {
          newCourseLinkNames[1] = currentCourse?.title;
        } else {
          newCourseLinkNames[1] = CREATECOURSE;
        }
        newBreadcrumbNames = newCourseLinkNames;
        setShowAction(true);
        setShowBottomAction(false);
        history.listen(() => {
          dispatch(validateRequestSuccess());
        });
        break;
      case PROFILEFLOW:
        setShowPageHeader(false);
        setShowAction(false);
        setShowBottomAction(false);
        break;
      case MANAGELIBRARYFLOW:
        let newLibraryLinkNames = [...libraryLinkName];
        newLibraryLinkNames[1] = question
          ? MANAGELIBRARY_EDIT
          : MANAGELIBRARY_ADD;
        newBreadcrumbNames = newLibraryLinkNames;

        setShowAction(false);
        setShowBottomAction(true);
        break;
      case MEDIAFLOW:
        newBreadcrumbNames = mediaLinkName;
        setShowAction(true);
        setShowBottomAction(false);
        break;
      case COMMISONDATAFLOW:
        newBreadcrumbNames = commissionDataLinkName;
        setShowAction(false);
        setShowBottomAction(true);
        setIsCommission(true);
        break;
      default:
        newBreadcrumbNames = [];
    }

    if (links.length > 1) {
      setShowAction(false);
      setShowBottomAction(false);
    }

    setBreadcrumNames(newBreadcrumbNames);
    setTitle(newBreadcrumbNames[links.length - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!processing && uploaded) {
      setShowPopupCreateMedia(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded]);

  const actionHandler = () => {
    switch (links[0]) {
      case COURSEFLOW:
        setShowAction(false);
        dispatch(prepareCreateCourse());
        history.push('/upload-course/create-new-course');
        break;
      case PROFILEFLOW:
        break;
      case MANAGELIBRARYFLOW:
        history.push('/manage-library/add-new');
        break;
      case MEDIAFLOW:
        setShowPopupCreateMedia(true);
        break;
      case COMMISONDATAFLOW:
        break;
      default:
    }
  };

  const renderAcrion = () => {
    let action = null;
    switch (links[0]) {
      case COURSEFLOW:
        action = (
          <Button onClick={actionHandler} className={'btn-primary'}>
            <i className="ri-add-fill"></i>
            <span>New Course</span>
          </Button>
        );
        break;
      case PROFILEFLOW:
        break;
      case MANAGELIBRARYFLOW:
        action = (
          <>
            <Button onClick={actionHandler} className={'btn-primary'}>
              <i className="ri-add-fill"></i>
              <span>Add Post</span>
            </Button>
            <Search
              value={libararySearchValue}
              onChange={handleLibrarySeachOnChange}
              placeholder={'Search...'}
              onSearch={libraryOnSearch}
              onClear={handleResetSearch}
            />
          </>
        );
        break;
      case MEDIAFLOW:
        action = (
          <Button onClick={actionHandler} className={'btn-primary'}>
            <i className="ri-add-fill"></i>
            <span>Upload new file</span>
          </Button>
        );
        break;
      case COMMISONDATAFLOW:
        break;
      default:
    }

    return action;
  };

  const handleLibrarySeachOnChange = (value) => {
    setLibararySearchValue(value);
  };

  const libraryOnSearch = () => {
    dispatch(searchKeywordOnChange(libararySearchValue));
  };

  const saveMedia = async (media) => {
    if (media) {
      dispatch(createMediaRequest(media));
    } else {
      toast.error('Please select a file');
    }
  };

  const handleResetSearch = () => {
    setLibararySearchValue('');
    dispatch(searchKeywordOnChange(''));
  };

  return (
    <>
      {showPageHeader && (
        <div className="page-title">
          {!isCommission && (
            <Breadcrumb links={links} breadcrumNames={breadcrumNames} />
          )}
          {!isViewDetail && (
            <div className="inner-title">
              <h1 className="title">{title}</h1>
              {showAction ? renderAcrion() : <></>}
            </div>
          )}
          {showBottomAction && (
            <div className="bottom-action">{renderAcrion()}</div>
          )}
          {isCommission && <CommissionInfo />}
        </div>
      )}
      {showPopupCreateMedia && (
        <ModalUploadMedia
          onClose={() => {
            setShowPopupCreateMedia(false);
          }}
          onConfirm={saveMedia}
        />
      )}
    </>
  );
};

export default PageHeader;
