import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import {
  GET_TEACHER_INFO_REQUEST,
  GET_USER_WALLET_REQUEST,
  UPDATE_TEACHER_INFO_REQUEST,
  UPDATE_TEACHER_NAME_REQUEST,
} from './constants';

import * as actions from './actions';
import { userService } from 'src/services/userService';

const get = new APIClient().get;

function* getUserWalletRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/users/wallet');

    yield put(actions.getUserWalletSuccess(response.data));
  } catch (error) {}
}

function* getTeacherInfoRequest({ payload }) {
  try {
    const response = yield call(userService.getTeacherInfo, payload);

    yield put(actions.getTeacherInfoSuccess(response.data));
  } catch (error) {}
}

function* updateTeacherInfoRequest({ payload }) {
  try {
    const response = yield call(userService.updateTeacherInfo, payload);

    yield put(actions.updateTeacherInfoSuccess(response.data));
    if(payload.cb){
      payload.cb(false)
    }
  } catch (error) {
    if(payload.cb){
      payload.cb(true)
    }
  }
}

function* updateTeacherNameRequest({ payload }) {
  try {
    const response = yield call(userService.updateTeacherName, payload);

    yield put(actions.updateTeacherNameSuccess(response.data));
    if(payload.cb){
      payload.cb(false)
    }
  } catch (error) {
    if(payload.cb){
      payload.cb(true)
    }
  }
}

export function* watchGetUserWalletReques() {
  yield takeEvery(GET_USER_WALLET_REQUEST, getUserWalletRequest);
}

export function* watchGetTeacherInfoRequest() {
  yield takeEvery(GET_TEACHER_INFO_REQUEST, getTeacherInfoRequest);
}

export function* watchUpdateTeacherInfoRequest() {
  yield takeEvery(UPDATE_TEACHER_INFO_REQUEST, updateTeacherInfoRequest);
}

export function* watchUpdateTeacherNameRequest() {
  yield takeEvery(UPDATE_TEACHER_NAME_REQUEST, updateTeacherNameRequest);
}

export default function* usersSaga() {
  yield all([
    fork(watchGetUserWalletReques),
    fork(watchGetTeacherInfoRequest),
    fork(watchUpdateTeacherInfoRequest),
    fork(watchUpdateTeacherNameRequest),
  ]);
}
