import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import usersSaga from './users/saga';
import questionsSaga from './questions/saga';
import notificationsSaga from './notifications/saga';
import generalDataSaga from './masterData/saga';
import courseSaga from './course/saga';
import mediaSaga from './media/saga';
import answerSaga from './answer/saga';
import transactionSaga from './mission-data/saga';
import courseDetailSaga from './course-detail/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    usersSaga(),
    questionsSaga(),
    notificationsSaga(),
    generalDataSaga(),
    courseSaga(),
    mediaSaga(),
    answerSaga(),
    transactionSaga(),
    courseDetailSaga(),
  ]);
}
