import {
  GET_QUESTIONS_DETAIL_FAILD,
  GET_QUESTIONS_DETAIL_REQUEST,
  GET_QUESTIONS_DETAIL_SUCCESS,
  GET_QUESTIONS_FAILD,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAILED,
  QUESTIONS_UNLOCK_FAILD,
  QUESTIONS_UNLOCK_REQUEST,
  QUESTIONS_UNLOCK_SUCCESS,
  QUESTIONS_FAVORITE_REQUEST,
  QUESTIONS_FAVORITE_FAILD,
  QUESTIONS_FAVORITE_SUCCESS,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILED,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILED,
  DELETE_QUESTION_REQUEST,
  CREATE_QUESTION_FINISH,
  UPDATE_QUESTION_FINISH,
  SET_CURRENT_QUESTION,
  DELETE_QUESTION_FAILED,
  CHANGE_KEYWORD,
  SET_PARAM,
} from './constants';

export const getQuestionsRequest = (payload) => ({
  type: GET_QUESTIONS_REQUEST,
  payload,
});

export const getQuestionsSuccess = (payload) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload,
});

export const getQuestionsFaild = () => ({
  type: GET_QUESTIONS_FAILD,
});

export const getQuestionDetailRequest = (payload) => ({
  type: GET_QUESTIONS_DETAIL_REQUEST,
  payload,
});

export const getQuestionDetailSuccess = (payload) => ({
  type: GET_QUESTIONS_DETAIL_SUCCESS,
  payload,
});

export const getQuestionDetailFaild = () => ({
  type: GET_QUESTIONS_DETAIL_FAILD,
});

export const filterQuestionsRequest = (payload) => ({
  type: FILTER_QUESTIONS_REQUEST,
  payload,
});

export const filterQuestionsSuccess = (payload) => ({
  type: FILTER_QUESTIONS_SUCCESS,
  payload,
});

export const filterQuestionsFailed = (payload) => ({
  type: FILTER_QUESTIONS_FAILED,
  payload,
});

export const questionUnlockRequest = (payload, callback) => ({
  type: QUESTIONS_UNLOCK_REQUEST,
  payload,
  callback,
});

export const questionUnlockSuccess = (payload) => ({
  type: QUESTIONS_UNLOCK_SUCCESS,
  payload,
});

export const questionUnlockFaild = () => ({
  type: QUESTIONS_UNLOCK_FAILD,
});

export const questionFavoriteRequest = (payload, callback) => ({
  type: QUESTIONS_FAVORITE_REQUEST,
  payload,
  callback,
});

export const questionFavoriteSuccess = (payload) => ({
  type: QUESTIONS_FAVORITE_SUCCESS,
  payload,
});

export const questionFavoriteFaild = () => ({
  type: QUESTIONS_FAVORITE_FAILD,
});

export const createQuestionRequest = (payload) => ({
  type: CREATE_QUESTION_REQUEST,
  payload,
});

export const createQuestionSuccess = (payload) => ({
  type: CREATE_QUESTION_SUCCESS,
  payload,
});

export const createQuestionFailed = (payload) => ({
  type: CREATE_QUESTION_FAILED,
  payload,
});

export const updateQuestionRequest = (payload) => ({
  type: UPDATE_QUESTION_REQUEST,
  payload,
});

export const updateQuestionSuccess = (payload) => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload,
});

export const updateQuestionFailed = (payload) => ({
  type: UPDATE_QUESTION_FAILED,
  payload,
});

export const deleteQuestionRequest = (payload) => ({
  type: DELETE_QUESTION_REQUEST,
  payload,
});

export const deleteQuestionSuccess = (payload) => ({
  type: DELETE_QUESTION_REQUEST,
  payload,
});

export const deleteQuestionFailed = (payload) => ({
  type: DELETE_QUESTION_FAILED,
  payload,
});

export const createQuestionFinish = () => ({
  type: CREATE_QUESTION_FINISH,
});

export const updateQuestionFinish = () => ({
  type: UPDATE_QUESTION_FINISH,
});

export const setCurrentQuestion = (payload) => ({
  type: SET_CURRENT_QUESTION,
  payload,
});

export const searchKeywordOnChange = (payload) => ({
  type: CHANGE_KEYWORD,
  payload,
});

export const setParam = (payload) => ({
  type: SET_PARAM,
  payload,
});
