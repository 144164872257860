import { envDevelopment } from "./env/envDevelopment";
import { envProduction } from "./env/envProduction";

const isDevelopment = false;
const envData = isDevelopment ? envDevelopment : envProduction
export default {
  ...envData,
  AUTH_BACKEND: 'Pombeebee',
  BEEBUX_NAME: 'beebux',
  BEEBUX_VS_VND: 100,
  BEEBUX_VS_CHARACTER: 300,
  STATUS_PENDING: 'PENDING',
  STATUS_PAID: 'PAID',
  STORAGE_KEY_USER: 'authUser',
  FACEBOOK_APP_ID: '429164781949326',
  APP_URL: 'https://teacher.pombeebee.com/',
};
