import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert } from 'reactstrap';
import { options } from './constant';
import { formatDateTime } from 'src/helpers/formatTime';
import moment from 'moment';

const ModalDatePicker = ({ dateFrom, dateTo, onConfirm, onClose }) => {
  const [startDate, setStartDate] = useState(dateFrom ? dateFrom : new Date());
  const [endDate, setEndDate] = useState(dateTo ? dateTo : new Date());
  const [showError, setShowError] = useState(false);
  const [selctedOption, setSelectedOption] = useState(0);
  const onFromDateChange = (start) => {
    setStartDate(start);
  };

  const onToDateChange = (end) => {
    setEndDate(end);
  };

  const handleOnConfirm = () => {
    if (endDate < startDate) {
      setShowError(true);
    } else {
      setShowError(false);
      onConfirm(startDate, endDate);
    }
  };

  const selectionOption = (option) => {
    switch (option) {
      case 1:
        setStartDate(null);
        setEndDate(null);
        break;
      case 2:
        let today = moment().valueOf();
        setStartDate(today);
        setEndDate(today);
        break;
      case 3:
        // let today2 = moment().valueOf();
        let yesterday = moment().subtract(1, 'days').toDate();
        setStartDate(yesterday);
        setEndDate(yesterday);
        break;
      case 4:
        const startOfMonth = moment().startOf('month').valueOf();
        const endOfMonth = moment().endOf('month').valueOf();
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
        break;
      case 5:
        const startOfLastMonth = moment()
          .subtract(1, 'months')
          .startOf('month')
          .valueOf();
        const endOfLastMonth = moment()
          .subtract(1, 'months')
          .endOf('month')
          .valueOf();
        setStartDate(startOfLastMonth);
        setEndDate(endOfLastMonth);
        break;
      case 6:
        const startOfYear = moment().startOf('year').valueOf();
        const endOfYear = moment().endOf('year').valueOf();
        setStartDate(startOfYear);
        setEndDate(endOfYear);
        break;
      default:
        return;
    }
    setSelectedOption(option);
  };
  return (
    <div className="popup popup-basic modal-datepicker">
      <div className="popup-wrapper">
        <div className="popup-content">
          <div className="popup-header">
            <div className="selected-info">
              <h5>Selected Period:</h5>
              <span>
                {startDate !== null && endDate !== null
                  ? `${formatDateTime(startDate)} to ${formatDateTime(endDate)}`
                  : 'All the time'}
              </span>
            </div>
            <i
              className="ri-close-line close"
              onClick={() => {
                onClose();
              }}
            ></i>
          </div>
          <div className="popup-body">
            {showError && (
              <Alert color="danger">
                End day much be greater than start date!
              </Alert>
            )}
            <div className="datepicker-section">
              <ReactDatePicker
                selected={startDate}
                onChange={onFromDateChange}
                inline
              />
              <ReactDatePicker
                selected={endDate}
                onChange={onToDateChange}
                inline
              />
              <div className="datepicker-action">
                {options.map((option, index) => {
                  return (
                    <div
                      className={
                        option.id === selctedOption
                          ? 'action-selection selected'
                          : 'action-selection'
                      }
                      key={index}
                      onClick={() => {
                        selectionOption(option.id);
                      }}
                    >
                      {option.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="footer">
            <button className="btn" onClick={onClose}>
              CANCEL
            </button>
            <button className="btn btn-primary" onClick={handleOnConfirm}>
              APPLY
            </button>
          </div>
        </div>
      </div>
      <div className="popup-backdrop"></div>
    </div>
  );
};

export default ModalDatePicker;
