import { toast } from 'react-toastify';

const handleError = (action) => {
  const status = action.payload?.status;
  const message = action.payload?.data?.data?.error;
  switch (status) {
    case 400:
    case 422:
      toast.error(message);
      break;
    default:
      break;
  }
};

export const throwMiddleware = () => (next) => (action) => {
  if (action.type.indexOf('FAILED') > 0) {
    handleError(action);
  }
  next(action);
};
