import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_LIST_COURSE_REQUEST,
  CREATE_COURSE_REQUEST,
  GET_LIST_SECTION_REQUEST,
  UPDATE_COURSE_REQUEST,
  DELETE_SECTION_REQUEST,
} from './constant';
import {
  getCoursesSuccess,
  createCourseSuccess,
  createCourseFaild,
  getSectionsSuccess,
  getSectionsFaild,
  getCoursesFaild,
  updateCourseSuccess,
  updateCourseFaild,
  deleteSectionSuccess,
  deleteSectionFaild,
} from './action';
import { courseService } from 'src/services/courseService';

function* getCoursesRequest(action) {
  try {
    const response = yield call(courseService.getCourses, action.payload);
    yield put(getCoursesSuccess(response.data));
  } catch (error) {
    yield put(getCoursesFaild(error));
  }
}

function* createCourseRequest(action) {
  try {
    const formData = new FormData();
    formData.append('title', action.payload.title);
    formData.append('price', action.payload.price);
    if (action.payload.sub_title) {
      formData.append('sub_title', action.payload.sub_title);
    }
    formData.append('description', action.payload.description);
    formData.append('requirement', action.payload.requirement);
    formData.append('status', action.payload.status);
    if (action.payload.image) {
      formData.append('image', action.payload.image);
    }

    action.payload.categories.forEach((item) =>
      formData.append('categories[]', item)
    );

    const courseResponse = yield call(courseService.createCourse, formData);
    yield put(createCourseSuccess(courseResponse.data));
  } catch (error) {
    yield put(createCourseFaild(error));
  }
}

function* updateCourseRequest(action) {
  const { course, isChangeThumbnail } = action.payload;
  try {
    const formData = new FormData();
    formData.append('title', course.title);
    formData.append('price', course.price);
    if (course.sub_title) {
      formData.append('sub_title', course.sub_title);
    }
    formData.append('description', course.description);
    formData.append('requirement', course.requirement);
    formData.append('status', course.status);
    if (isChangeThumbnail) {
      formData.append('image', course.image);
    }

    course.categories.forEach((item) => formData.append('categories[]', item));

    const courseResponse = yield call(courseService.updateCourse, {
      formData,
      id: course.id,
    });
    yield put(updateCourseSuccess(courseResponse.data));
  } catch (error) {
    yield put(updateCourseFaild(error));
  }
}

function* getSectionsRequest(action) {
  try {
    const response = yield call(courseService.getSections, action.payload);
    yield put(getSectionsSuccess(response.data));
  } catch (error) {
    yield put(getSectionsFaild(error));
  }
}

function* deleteSectionsRequest(action) {
  try {
    const response = yield call(courseService.deleteSection, action.payload);
    yield put(deleteSectionSuccess(response.data));
  } catch (error) {
    yield put(deleteSectionFaild(error));
  }
}

export function* watchGetCoursesRequest() {
  yield takeEvery(GET_LIST_COURSE_REQUEST, getCoursesRequest);
}

export function* watchCeateCourseRequest() {
  yield takeEvery(CREATE_COURSE_REQUEST, createCourseRequest);
}
export function* watchUpdateCourseRequest() {
  yield takeEvery(UPDATE_COURSE_REQUEST, updateCourseRequest);
}

export function* watchGetSectionsRequest() {
  yield takeEvery(GET_LIST_SECTION_REQUEST, getSectionsRequest);
}

export function* watchDeleteSectionsRequest() {
  yield takeEvery(DELETE_SECTION_REQUEST, deleteSectionsRequest);
}

export default function* courseSaga() {
  yield all([
    fork(watchCeateCourseRequest),
    fork(watchUpdateCourseRequest),
    fork(watchGetCoursesRequest),
    fork(watchGetSectionsRequest),
    fork(watchDeleteSectionsRequest),
  ]);
}
