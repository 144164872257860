import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_LIST_TRANSACTION_REQUEST } from './constant';
import { getTransactionsFaild, getTransactionsSuccess } from './action';
import { transactionService } from 'src/services/transactionService';

function* getTransactionsRequest(action) {
  try {
    const response = yield call(
      transactionService.getTransactions,
      action.payload
    );
    yield put(getTransactionsSuccess(response.data));
  } catch (error) {
    yield put(getTransactionsFaild(error));
  }
}

export function* watchGetTransactionsRequest() {
  yield takeEvery(GET_LIST_TRANSACTION_REQUEST, getTransactionsRequest);
}

export default function* transactionSaga() {
  yield all([fork(watchGetTransactionsRequest)]);
}
