export const CREATE_ANSWER_REQUEST = 'CREATE_ANSWER_REQUEST';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_FAILED = 'CREATE_ANSWER_FAILED';

export const UPDATE_ANSWER_REQUEST = 'UPDATE_ANSWER_REQUEST';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAILED = 'UPDATE_ANSWER_FAILED';

export const DELETE_ANSWER_REQUEST = 'DELETE_ANSWER_REQUEST';
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS';
export const DELETE_ANSWER_FAILED = 'DELETE_ANSWER_FAILED';
