import React from 'react';

const Search = ({ value, onChange, onSearch, placeholder, onClear }) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };
  const onSearchHandler = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };
  return (
    <div className="search">
      <i className="ri-search-line"></i>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onKeyDown={onSearchHandler}
      />
      {value && <i className="ri-close-circle-fill" onClick={onClear}></i>}
    </div>
  );
};

export default Search;
