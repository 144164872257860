import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { getAccessToken } from 'src/helpers/user';

const apiClient = new APIClient();
const courseService = {
  getCourses: (params) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/courses`, { params });
  },
  getCourse: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/courses/${id}`);
  },
  createCourse: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/courses`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateCourse: ({ formData, id }) => {
    setAuthorization(getAccessToken());
    return apiClient.updateViaPost(`/api/courses/${id}?_method=PUT`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getSections: (courseId) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/course-sections?course_id=${courseId}`);
  },
  getSection: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/course-sections/${id}`);
  },
  createSection: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/course-sections`, payload);
  },
  updateSection: (payload) => {
    setAuthorization(getAccessToken());
    const result =  apiClient.update(`/api/course-sections/${payload.section_id}`, {name: payload.name});
    return result
  },
  getLectures: (sectionId) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/section-lectures?section-id=${sectionId}`);
  },
  createLecture: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/section-lectures`, payload);
  },
  updateLecture: ({...payload}) => {
    setAuthorization(getAccessToken());
    return apiClient.create(`/api/section-lectures/${payload.id}`, payload.formData);
  },
  deleteSection: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.delete(`/api/course-sections/${id}`);
  },
  sortSection: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.update(`/api/course-sections/bulk-update-order`, payload);
  },
  sortLecture: (payload) => {
    setAuthorization(getAccessToken());
    return apiClient.update(`/api/section-lectures/bulk-update-order`, payload);
  },
  deleteLecture: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.delete(`/api/section-lectures/${id}`);
  }
};
export { courseService };
