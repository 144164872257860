import React, { useState } from 'react';
import BasicSelect from '../common/BasicSelect';
import InputIcon from '../InputIcon';
import ModalDatePicker from '../ModalDatePicker';
import { formatDateTime } from 'src/helpers/formatTime';
import { useDispatch, useSelector } from 'react-redux';
import {  filterTraction } from 'src/redux/mission-data/action';
import CurrencyFormat from 'react-currency-format';

const CommissionInfo = () => {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { total_commission, total_revenue } = useSelector(
    (state) => state.Transaction
  );
  const { courses } = useSelector((state) => state.Course);

  const handleOnSelect = (course) => {
    dispatch(
      filterTraction({
        dateFrom: formatDateTime(dateFrom),
        dateTo: formatDateTime(dateTo),
        course: course?.title,
      })
    );
    setSelectedCourse(course);
  };
  const handleOnSelectDate = (dateFrom, dateTo) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setShowDatePicker(false);
    dispatch(
      filterTraction({
        dateFrom: formatDateTime(dateFrom),
        dateTo: formatDateTime(dateTo),
        course: selectedCourse?.title,
      })
    );
  };
  const handleCloseDatePicker = () => setShowDatePicker(false);
  const handleOPenDatePicker = () => setShowDatePicker(true);
  const handleClearFilter = (e) => {
    e.stopPropagation();
    setDateFrom(null);
    setDateTo(null);
    dispatch(
      filterTraction({
        dateFrom: null,
        dateTo: null,
        course: selectedCourse?.title,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="commisstion-info">
        <div className="commission-action">
          <div className="form-group inline">
            <label>Choose the course</label>
            <BasicSelect
              data={courses}
              defaultTitile={''}
              placeholder="All course"
              onSelect={handleOnSelect}
              isFilter={true}
            />
          </div>
          <div className="form-group inline mt-0">
            <label>Choose time period</label>
            <InputIcon
              value={
                dateFrom !== null && dateTo !== null
                  ? `${formatDateTime(dateFrom)} to ${formatDateTime(dateTo)}`
                  : ''
              }
              placeholder={'All the time  '}
              icon={<i className="ri-calendar-2-line"></i>}
              onClick={handleOPenDatePicker}
              clear={true}
              readOnly
              onClear={handleClearFilter}
            />
          </div>
        </div>
        <div className="commission-total">
          <div className="total-number">
            <label>total revenue:</label>
            <h6>
              <CurrencyFormat
                value={total_revenue * 100}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="currency-unit">VND</span>
            </h6>
          </div>
          <div className="total-number">
            <label>TOTAL COMMISSION:</label>
            <h6>
              <CurrencyFormat
                value={total_commission * 100}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span className="currency-unit">VND</span>
            </h6>
          </div>
        </div>
      </div>
      {showDatePicker && (
        <ModalDatePicker
          onClose={handleCloseDatePicker}
          onConfirm={handleOnSelectDate}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
    </React.Fragment>
  );
};

export default CommissionInfo;
