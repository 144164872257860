import React from 'react';
import classnames from 'classnames';
import './button.scss';
const Button = ({ children, className, type, onClick, disabled }) => {
  const classNamses = classnames('btn', { [`${className}`]: true });
  return (
    <button
      className={classNamses}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
