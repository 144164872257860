import {
  GET_LIST_MEDIA_SUCCESS,
  GET_LIST_MEDIA_REQUEST,
  GET_LIST_MEDIA_FAILED,
  CREATE_MEDIA_REQUEST,
  CREATE_MEDIA_SUCCESS,
  CREATE_MEDIA_FAILED,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
} from './constant';

//MEDIA
export const getMediasRequest = (payload) => ({
  type: GET_LIST_MEDIA_REQUEST,
  payload,
});

export const getMediasSuccess = (payload) => ({
  type: GET_LIST_MEDIA_SUCCESS,
  payload,
});

export const getMediasFaild = () => ({
  type: GET_LIST_MEDIA_FAILED,
});

export const createMediaRequest = (payload) => ({
  type: CREATE_MEDIA_REQUEST,
  payload,
});

export const createMediaSuccess = (payload) => ({
  type: CREATE_MEDIA_SUCCESS,
  payload,
});

export const createMediaFaild = (payload) => ({
  type: CREATE_MEDIA_FAILED,
  payload,
});

export const deleteMediaRequest = (payload) => ({
  type: DELETE_MEDIA_REQUEST,
  payload,
});

export const deleteMediaSuccess = (payload) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload,
});

export const deleteMediaFaild = () => ({
  type: DELETE_MEDIA_FAILED,
});
