import React from 'react';

import { Link } from 'react-router-dom';

const Breadcrumb = ({ links, breadcrumNames }) => {
  let count = 0;
  let currentLink = '';
  return (
    <div className="pbb-breadcrumbs">
      <div className="pbb-breadcrumb">
        <Link to={'/'}>Home</Link>
        <span className="pbb-breadcrumb__separator">/</span>
      </div>
      {links.map((link) => {
        if (link !== links[links.length - 1]) {
          currentLink += `/${link}`;
          count += 1;
          return (
            <div className="pbb-breadcrumb" key={link}>
              <Link to={currentLink}>{breadcrumNames[count - 1]}</Link>
              <span className="pbb-breadcrumb__separator">/</span>
            </div>
          );
        } else {
          return (
            <span className="pbb-breadcrumb__link--current" key={link}>
              {breadcrumNames[links.length - 1]}
            </span>
          );
        }
      })}
    </div>
  );
};

export default Breadcrumb;
