import {
  CREATE_ANSWER_REQUEST,
  CREATE_ANSWER_SUCCESS,
  CREATE_ANSWER_FAILED,
  UPDATE_ANSWER_REQUEST,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILED,
  DELETE_ANSWER_REQUEST,
} from './constants';

export const createAnswerRequest = (payload) => ({
  type: CREATE_ANSWER_REQUEST,
  payload,
});

export const createAnswerSuccess = (payload) => ({
  type: CREATE_ANSWER_SUCCESS,
  payload,
});

export const createAnswerFailed = (payload) => ({
  type: CREATE_ANSWER_FAILED,
  payload,
});

export const updateAnswerRequest = (payload) => ({
  type: UPDATE_ANSWER_REQUEST,
  payload,
});

export const updateAnswerSuccess = (payload) => ({
  type: UPDATE_ANSWER_SUCCESS,
  payload,
});

export const updateAnswerFailed = (payload) => ({
  type: UPDATE_ANSWER_FAILED,
  payload,
});

export const deleteAnswerRequest = (payload) => ({
  type: DELETE_ANSWER_REQUEST,
  payload,
});

export const deleteAnswerSuccess = (payload) => ({
  type: DELETE_ANSWER_REQUEST,
  payload,
});

export const deleteAnswerFailed = (payload) => ({
  type: DELETE_ANSWER_REQUEST,
  payload,
});
