import React, { useRef, useState } from 'react';
import videoThumbnail from '../..//assets/images/videoThumbnail.png';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
const ModalUploadMedia = ({
  title = 'UPLOAD MEDIA',
  onClose,
  onConfirm,
}) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const fileRef = useRef(null);

  const uploadFileOnClick = () => fileRef.current.click();
  const handleOnFileChange = (e) => {
    const file = e.target.files[0];
    let fileUrl = window.URL.createObjectURL(file);
    let url = file.type.includes('video') ? videoThumbnail : fileUrl;
    setIsVideo(false);
    if (file.type.includes('video')) {
      setIsVideo(true);
    }
    setUrl(url);
    setFile(file);
  };
  const handleConfirm = () => {
    onConfirm(file);
  };
  return (
    <div className="popup popup-basic">
      <div className="popup-wrapper">
        <div className="popup-content">
          <div className="popup-header">
            <h5>{title}</h5>
            <i
              className="ri-close-line close"
              onClick={() => {
                onClose();
              }}
            ></i>
          </div>
          <div className="popup-body">
            <div className="upload-zone" onClick={uploadFileOnClick}>
              {url && (
                <>
                  {isVideo ? (
                    <Player>
                      <source src={url} />
                    </Player>
                  ) : (
                    <img src={url} alt="" className="preview" />
                  )}
                </>
              )}
              {!url && (
                <div className="upload-placeholder">
                  <i className="ri-upload-2-line"></i>
                  <span>Select a file...</span>
                </div>
              )}
              <input
                type="file"
                value={''}
                ref={fileRef}
                onChange={handleOnFileChange}
                accept=".jpg,.png,.jpeg,.gif,.mp4,.mov,.avi,.webm,.f4v,.wmv"
              />
            </div>
            <div className="media-info">
              <div className="form-group"> </div>
            </div>
          </div>
          <div className="footer">
            <button className="btn" onClick={onClose}>
              CANCEL
            </button>
            <button className="btn btn-primary" onClick={handleConfirm}>
              UPLOAD
            </button>
          </div>
        </div>
      </div>
      <div className="popup-backdrop"></div>
    </div>
  );
};

export default ModalUploadMedia;
