import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_UNREAD_NOTIFICATION_SUCCESS,
  OPEN_NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  MARK_READ_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION,
} from './constants';

const INIT_STATE = {
  notifications: [],
  last_page: 0,
  openPopup: false,
  unreadNotification: 0,
};

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      const { data, current_page } = action.payload;
      return {
        ...state,
        notifications:
          current_page === 1 ? data : [...state.notifications, ...data],
        last_page: action.payload.last_page,
      };
    }
    case OPEN_NOTIFICATIONS: {
      return {
        ...state,
        openPopup: !state.openPopup,
      };
    }
    case CLOSE_NOTIFICATIONS: {
      return {
        ...state,
        openPopup: false,
      };
    }
    case GET_UNREAD_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        unreadNotification: action.payload.unread_notification,
      };
    }
    case MARK_READ_NOTIFICATION_SUCCESS: {
      state.notifications.forEach((noti) => {
        noti.status = 'READ';
      });
      return {
        ...state,
        unreadNotification: 0,
      };
    }
    case ADD_NOTIFICATION: {
      const new_notification = action.payload;
      const result = state.notifications.includes(
        (noti) => noti.id === new_notification.id
      );
      if (!result) {
        return {
          ...state,
          notifications: [new_notification, ...state.notifications],
          unreadNotification: state.unreadNotification + 1,
        };
      }
      return { ...state };
    }
    default:
      return { ...state };
  }
};

export default Notifications;
