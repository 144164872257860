import React, { useCallback, useEffect, useRef, useState } from 'react';
import useClickOutside from 'src/helpers/useClickOutside';
import { KEY_LANGUAGE } from 'src/redux/auth/constants';
import dropDownIcon from 'src/assets/images/Icons/Dropdown.svg';

const BasicSelect = ({
  data,
  placeholder,
  defaultTitile,
  onSelect,
  isReset,
  isFilter,
}) => {
  const [defaultTitle, setDefaultTitle] = useState(defaultTitile);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [language, setLanguage] = useState();

  useEffect(() => {
    setLanguage(localStorage.getItem(KEY_LANGUAGE));
  }, [language]);

  const onClickOutsideCallback = useCallback(() => {
    if (!wrapperRef.current) return false;
    setIsOpen(false);
  }, []);

  useClickOutside(wrapperRef, onClickOutsideCallback);

  const handleSelect = (data) => {
    setIsOpen((prevState) => !prevState);
    setDefaultTitle(data.title);
    onSelect(data);
  };

  const handleClearFilter = (e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
    setDefaultTitle('');
    onSelect(null);
  };

  useEffect(() => {
    if (isReset) setDefaultTitle(defaultTitile);
  }, [isReset, defaultTitile]);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div
      className={isFilter ? 'option-custom filter' : 'option-custom'}
      ref={wrapperRef}
    >
      <div className="select-input" onClick={handleOpen}>
        <div>
          <span
            className={`${
              placeholder && defaultTitle === ''
                ? 'select-title placeholder'
                : 'select-title'
            }`}
            title={defaultTitle}
          >
            {defaultTitle === '' ? placeholder : defaultTitle}
          </span>
        </div>
        <div>
          <img alt="Select Topic" src={dropDownIcon} />
        </div>
        {isFilter && defaultTitle !== '' && (
          <i
            className="ri-close-circle-fill clear"
            onClick={handleClearFilter}
          ></i>
        )}
      </div>

      {isOpen ? (
        <div className="questionWrapper">
          <div className="list_question_content" style={{ zIndex: '999' }}>
            <div className="select-list">
              {data &&
                data.map((item) => {
                  if (item.id === -1) {
                    return null;
                  }
                  return (
                    <div
                      key={item.id}
                      onClick={() => handleSelect(item)}
                      className="select-item"
                    >
                      <span className="select-title">{item.title}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default BasicSelect;
