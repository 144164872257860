export const options = [
  {
    id: 1,
    name: 'All the time',
  },
  {
    id: 2,
    name: 'Today',
  },
  {
    id: 3,
    name: 'Yesterday',
  },
  {
    id: 4,
    name: 'This month',
  },
  {
    id: 5,
    name: 'Last month',
  },
  {
    id: 6,
    name: 'This year',
  },
];
