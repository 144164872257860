export const IMAGEFILES = ['jpg', 'png', 'jpeg', 'gif'];
export const VIDEOFILES = ['mp4', 'mov', 'avi', 'webm', 'f4v'];
export const DATE_FORMAT = 'yyyy-MM-dd';
export const SCROLLTOP = 'main-content';

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
};

export const PROFILE_OPTIONS = {
  PROFILE: 'PROFILE',
  SETTINGS: 'SETTINGS',
};

export const NOTIFICATION_TYPES = {
  NEW_QUESTION: 'NEW_QUESTION',
  POST_COMMENT: 'POST_COMMENT',
  POST_LIKE: 'POST_LIKE',
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  SYSTEM: 'SYSTEM',
  ATTACHMENT: 'ATTACHMENT',
};

export const LOCK_STATUS = {
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
};

export const COURSE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLIC: 'PUBLIC',
  HIDDEN: 'HIDDEN',
};

export const PROFILEFLOW = 'profile';
export const MANAGELIBRARYFLOW = 'manage-library';
export const COURSEFLOW = 'upload-course';
export const MEDIAFLOW = 'media';
export const COMMISONDATAFLOW = 'commission-data';

export const PROFILE = 'Profile';
export const ONLINECOURSE = 'Online course';
export const CREATECOURSE = 'Create new course';
export const EDITCOURSE = 'Edit course';
export const COURSEDETAIL = 'Course detail';
export const MANAGELIBRARY = 'Manage library';
export const MEDIA = 'Media';
export const COMMISONDATA = 'Commission data';

export const profileLinkName = [PROFILE];
export const courseLinkName = [ONLINECOURSE, CREATECOURSE, COURSEDETAIL];
export const libraryLinkName = [MANAGELIBRARY];
export const mediaLinkName = [MEDIA];
export const commissionDataLinkName = [COMMISONDATA];

export const BREADCRUMB = {
  PROFILEFLOW: 'profile',
  MANAGELIBRARYFLOW: 'manage-library',
  COURSEFLOW: 'upload-course',
  MEDIAFLOW: 'media',
  COMMISONDATAFLOW: 'commission-data',
  PROFILE: 'Profile',
  ONLINECOURSE: 'Online course',
  CREATECOURSE: 'Create new course',
  EDITCOURSE: 'Edit course',
  COURSEDETAIL: 'Course detail',
  MANAGELIBRARY: 'Manage library',
  MEDIA: 'Media',
  COMMISONDATA: 'Commission data',
};
export const STATUS_PAYSLIP = {
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  ADMIN: "ADMIN",
  IN_KIND: "IN_KIND"
}
