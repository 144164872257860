import {
  CHANGE_KEYWORD,
  CREATE_QUESTION_FAILED,
  CREATE_QUESTION_FINISH,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILED,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  FILTER_QUESTIONS_REQUEST,
  GET_QUESTIONS_DETAIL_FAILD,
  GET_QUESTIONS_DETAIL_REQUEST,
  GET_QUESTIONS_DETAIL_SUCCESS,
  GET_QUESTIONS_FAILD,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  SET_CURRENT_QUESTION,
  UPDATE_QUESTION_FAILED,
  UPDATE_QUESTION_FINISH,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
} from './constants';

const INIT_STATE = {
  isFetchingQuestion: false,
  questions: [],
  question: null,
  last_page: 0,
  questionProcessing: false,
  processing: false,
  loading_more: false,
  hasError: false,
  errorMessage: '',
  isEdit: false,
  deleteResult: false,
  keyword: '',
  startSearch: false,
  finishSearch: false,
};

const Questions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTIONS_SUCCESS: {
      const { data, last_page, current_page } = action.payload.data;
      return {
        ...state,
        questions: current_page === 1 ? data : [...state.questions, ...data],
        last_page: last_page,
        processing: false,
        loading_more: false,
        question: null,
        isEdit: false,
        deleteResult: false,
        startSearch: false,
        finishSearch: false,
      };
    }
    case GET_QUESTIONS_REQUEST: {
      const { page } = action.payload;
      return {
        ...state,
        processing: page === 1,
        loading_more: page > 1,
      };
    }
    case GET_QUESTIONS_FAILD: {
      return {
        ...state,
        processing: false,
        loading_more: false,
      };
    }

    case GET_QUESTIONS_DETAIL_REQUEST: {
      return {
        ...state,
        isFetchingQuestion: true,
      };
    }

    case GET_QUESTIONS_DETAIL_SUCCESS: {
      return {
        ...state,
        isFetchingQuestion: false,
        question: action.payload,
      };
    }

    case GET_QUESTIONS_DETAIL_FAILD: {
      return {
        ...state,
        isFetchingQuestion: false,
      };
    }

    case FILTER_QUESTIONS_REQUEST: {
      return { ...state };
    }

    case CREATE_QUESTION_REQUEST: {
      return {
        ...state,
        questionProcessing: true,
        processing: true,
        hasError: false,
        isEdit: false,
      };
    }

    case CREATE_QUESTION_SUCCESS: {
      return {
        ...state,
        question: action.payload,
        questionProcessing: false,
        hasError: false,
      };
    }

    case CREATE_QUESTION_FAILED: {
      return {
        ...state,
        processing: false,
        questionProcessing: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case UPDATE_QUESTION_REQUEST: {
      return {
        questionProcessing: true,
        processing: true,
        hasError: false,
        isEdit: true,
      };
    }

    case UPDATE_QUESTION_SUCCESS: {
      return {
        ...state,
        question: action.payload,
        questionProcessing: false,
        hasError: false,
      };
    }

    case UPDATE_QUESTION_FAILED: {
      return {
        ...state,
        processing: false,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case DELETE_QUESTION_REQUEST: {
      return {
        ...state,
        processing: true,
        hasError: false,
        deleteResult: false,
      };
    }

    case DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        processing: false,
        deleteResult: true,
      };
    }

    case DELETE_QUESTION_FAILED: {
      return {
        ...state,
        processing: false,
        deleteResult: true,
        hasError: true,
        errorMessage: action.payload,
      };
    }

    case CREATE_QUESTION_FINISH: {
      return {
        ...state,
        processing: false,
      };
    }

    case UPDATE_QUESTION_FINISH: {
      return {
        ...state,
        processing: false,
        isEdit: true,
      };
    }

    case SET_CURRENT_QUESTION: {
      return {
        ...state,
        question: action.payload,
        processing: false,
        isEdit: true,
      };
    }

    case CHANGE_KEYWORD: {
      return {
        ...state,
        startSearch: state.keyword === '' && action.payload !== '',
        finishSearch: state.keyword !== '' && action.payload === '',
        keyword: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default Questions;
