import {
  GET_TEACHER_INFO_SUCCESS,
  GET_USER_WALLET_SUCCESS,
  UPDATE_TEACHER_INFO_SUCCESS,
  UPDATE_TEACHER_NAME_REQUEST,
} from './constants';

const INIT_STATE = {
  wallet: '',
  teacherInfo: {},
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_WALLET_SUCCESS: {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    case GET_TEACHER_INFO_SUCCESS: {
      return {
        ...state,
        teacherInfo: action.payload,
      };
    }
    case UPDATE_TEACHER_INFO_SUCCESS: {
      return {
        ...state,
        teacherInfo: action.payload,
      };
    }
    case UPDATE_TEACHER_NAME_REQUEST: {
      return {
        ...state,
        teacherInfo: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default Users;
