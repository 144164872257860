export const GET_LIST_MEDIA_REQUEST = 'GET_LIST_MEDIA_REQUEST';
export const GET_LIST_MEDIA_SUCCESS = 'GET_LIST_MEDIA_SUCCESS';
export const GET_LIST_MEDIA_FAILED = 'GET_LIST_MEDIA_FAILED';

export const CREATE_MEDIA_REQUEST = 'CREATE_MEDIA_REQUEST';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAILED = 'CREATE_MEDIA_FAILED';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';
