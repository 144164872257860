import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageHeader from 'src/components/PageHeader';
import Button from 'src/components/common/Button';
import ContainerEx from 'src/components/common/Container';
import { logoutUser } from 'src/redux/actions';

const RightPanel = function ({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    dispatch(logoutUser(history));
  };
  return (
    <div className="main-content" id="main-content">
      <div className="header">
        <ContainerEx>
          <div className="logout">
            <Button className={'btn-logout'} onClick={logout}>
              <i className="ri-logout-box-line"></i>
              <span>Logout</span>
            </Button>
          </div>
        </ContainerEx>
      </div>
      <div className="content">
        <PageHeader />
        <div className="page-content">
          <ContainerEx>{children}</ContainerEx>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
