import React from 'react';
import LogoImage from '../assets/images/logo-light.png';
const Logo = () => {
  return (
    <div className="logo-ex">
      <img src={LogoImage} alt="logo" />
    </div>
  );
};

export default Logo;
