import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { formatDateTime } from 'src/helpers/formatTime';
import { getAccessToken } from 'src/helpers/user';

const apiClient = new APIClient();
const userService = {
  verifyToken: async () => {
    setAuthorization(getAccessToken());
    try {
      await apiClient.get('/api/users/me');
      return true;
    } catch (err) {
      return false;
    }
  },

  changePassword: async (input) => {
    setAuthorization(getAccessToken());
    try {
      await apiClient.update('/api/users/change-password', input);
      return true;
    } catch (err) {
      return false;
    }
  },

  log: async (id) => {
    const data = {
      last_active: formatDateTime(new Date(), 'YYYY-MM-DD HH:mm:ss'),
    };
    setAuthorization(getAccessToken());
    try {
      await apiClient.update('/api/users/' + id, data);
      return true;
    } catch (err) {
      return false;
    }
  },
  getTeacherInfo: (id) => {
    setAuthorization(getAccessToken());
    return apiClient.get(`/api/users/${id}?teacher_info=true`);
  },
  updateTeacherInfo: ({ id, bio }) => {
    setAuthorization(getAccessToken());
    return apiClient.update(`/api/users/${id}/teacher`, { bio });
  },
  updateTeacherName: ({ id, name }) => {
    setAuthorization(getAccessToken());
    return apiClient.update(`/api/users/${id}`, { name });
  },
};
export { userService };
