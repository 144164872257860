import React, { useEffect } from 'react';
import Routes from './routes/';

//Import Scss
import './assets/scss/themes.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LANGUAGE } from './pages/Library/constants';

function App() {
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.Auth.user);
  const languageDefault = auth?.user?.language;

  useEffect(() => {
    i18n.changeLanguage(languageDefault || LANGUAGE.EN);
  }, [i18n, languageDefault]);

  return <Routes />;
}
export default App;
