import i18next from 'i18next';
import moment from 'moment';

export const SORT_TYPE = {
  MOST_VIEW: 'MOST_VIEW',
  MOST_RECENT: 'MOST_RECENT',
};

export const DATA_PRICE = [
  {
    title: () => i18next.t('price_all'),
    id: 1,
    value: undefined,
  },
  {
    title: () => i18next.t('price_freemium'),
    id: 2,
    value: true,
  },
  {
    title: () => i18next.t('price_premium'),
    id: 3,
    value: false,
  },
];

export const DATA_SORT = [
  {
    title: () => i18next.t('most_recent'),
    id: SORT_TYPE.MOST_RECENT,
    value: SORT_TYPE.MOST_RECENT,
  },
  {
    title: () => i18next.t('most_view'),
    id: SORT_TYPE.MOST_VIEW,
    value: SORT_TYPE.MOST_VIEW,
  },
];

export const DATA_POSTED = [
  {
    title: 'Beginning of time',
    id: -1,
    value: '',
  },
  {
    title: '3 days ago',
    id: 1,
    value: moment().subtract(3, 'd').format('YYYY-MM-DD'),
  },
  {
    title: '7 days ago',
    id: 2,
    value: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  },
  {
    title: '14 days ago',
    id: 3,
    value: moment().subtract(14, 'd').format('YYYY-MM-DD'),
  },
  {
    title: '1 month ago',
    id: 4,
    value: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  },
  {
    title: '3 month ago',
    id: 5,
    value: moment().subtract(3, 'months').format('YYYY-MM-DD'),
  },
  {
    title: '6 month ago',
    id: 6,
    value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
  },
];

export const LANGUAGE = {
  EN: 'en',
  VI: 'vi',
};

export const ACTION_EDIT_AVATAR = {
  CROP_IMAGE: 'CROP_IMAGE',
  SELECT_IMAGE: 'SELECT_IMAGE',
};
