import {
  GET_LIST_COURSE_SUCCESS,
  GET_LIST_COURSE_REQUEST,
  GET_LIST_COURSE_FAILED,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_FAILED,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILED,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILED,
  GET_SECTION_REQUEST,
  GET_SECTION_SUCCESS,
  GET_SECTION_FAILED,
  GET_LIST_SECTION_REQUEST,
  GET_LIST_SECTION_SUCCESS,
  GET_LIST_SECTION_FAILED,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAILED,
  GET_LIST_LECTURE_REQUEST,
  GET_LIST_LECTURE_SUCCESS,
  GET_LIST_LECTURE_FAILED,
  CREATE_LECTURE_REQUEST,
  CREATE_LECTURE_SUCCESS,
  CREATE_LECTURE_FAILED,
  UPDATE_SECTION_REQUEST,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAILED,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILED,
  DELETE_SECTION_FAILED,
  DELETE_LECTURE_REQUEST,
  DELETE_LECTURE_SUCCESS,
  DELETE_LECTURE_FAILED,
  UPDATE_CURRENT_COURSE,
  PREPARE_CREATE_COURSE,
  UPDATE_CURRENT_SECTION,
  VALIDATE_FAILED,
  VALIDATE_SUCCESS,
  FINNISH_CREATE,
  RESET_PROCESS_STATE,
  BEGIN_SAVEDRAFT,
  END_SAVEDRAFT,
  BEGIN_SUBMIT,
  END_SUBMIT,
  UPDATE_LECTURE_REQUEST,
  UPDATE_LECTURE_SUCCESS,
  UPDATE_LECTURE_FAILED
} from './constant';

//COURSE
export const getCoursesRequest = (payload) => ({
  type: GET_LIST_COURSE_REQUEST,
  payload,
});

export const getCoursesSuccess = (payload) => ({
  type: GET_LIST_COURSE_SUCCESS,
  payload,
});

export const getCoursesFaild = () => ({
  type: GET_LIST_COURSE_FAILED,
});

export const getCourseRequest = (payload) => ({
  type: GET_COURSE_REQUEST,
  payload,
});

export const getCourseSuccess = (payload) => ({
  type: GET_COURSE_SUCCESS,
  payload,
});

export const getCourseFaild = () => ({
  type: GET_COURSE_FAILED,
});

export const createCourseRequest = (payload) => ({
  type: CREATE_COURSE_REQUEST,
  payload,
});

export const createCourseSuccess = (payload) => ({
  type: CREATE_COURSE_SUCCESS,
  payload,
});

export const createCourseFaild = (payload) => ({
  type: CREATE_COURSE_FAILED,
  payload,
});

export const updateCourseRequest = (payload) => ({
  type: UPDATE_COURSE_REQUEST,
  payload,
});

export const updateCourseSuccess = (payload) => ({
  type: UPDATE_COURSE_SUCCESS,
  payload,
});

export const updateCourseFaild = () => ({
  type: UPDATE_COURSE_FAILED,
});

export const deleteCourseRequest = (payload) => ({
  type: DELETE_COURSE_REQUEST,
  payload,
});

export const deleteCourseSuccess = (payload) => ({
  type: DELETE_COURSE_SUCCESS,
  payload,
});

export const deleteCourseFaild = () => ({
  type: DELETE_COURSE_FAILED,
});

//SECTION
export const getSectionsRequest = (payload) => ({
  type: GET_LIST_SECTION_REQUEST,
  payload,
});

export const getSectionsSuccess = (payload) => ({
  type: GET_LIST_SECTION_SUCCESS,
  payload,
});

export const getSectionsFaild = () => ({
  type: GET_LIST_SECTION_FAILED,
});

export const getSectionRequest = (payload) => ({
  type: GET_SECTION_REQUEST,
  payload,
});

export const getSectionSuccess = (payload) => ({
  type: GET_SECTION_SUCCESS,
  payload,
});

export const getSectionFaild = () => ({
  type: GET_SECTION_FAILED,
});

export const createSectionRequest = (payload) => ({
  type: CREATE_SECTION_REQUEST,
  payload,
});

export const createSectionSuccess = (payload) => ({
  type: CREATE_SECTION_SUCCESS,
  payload,
});

export const createSectionFaild = () => ({
  type: CREATE_SECTION_FAILED,
});

export const updateSectionRequest = (payload) => ({
  type: UPDATE_SECTION_REQUEST,
  payload,
});

export const updateSectionSuccess = (payload) => ({
  type: UPDATE_SECTION_SUCCESS,
  payload,
});

export const updateSectionFaild = () => ({
  type: UPDATE_SECTION_FAILED,
});

export const deleteSectionRequest = (payload) => ({
  type: DELETE_SECTION_REQUEST,
  payload,
});

export const deleteSectionSuccess = (payload) => ({
  type: DELETE_SECTION_SUCCESS,
  payload,
});

export const deleteSectionFaild = () => ({
  type: DELETE_SECTION_FAILED,
});

//LECTURE
export const getLecturesRequest = (payload) => ({
  type: GET_LIST_LECTURE_REQUEST,
  payload,
});
export const getLecturesSuccess = (payload) => ({
  type: GET_LIST_LECTURE_SUCCESS,
  payload,
});
export const getLecturesFaild = () => ({
  type: GET_LIST_LECTURE_FAILED,
});

export const createLectureRequest = (payload) => ({
  type: CREATE_LECTURE_REQUEST,
  payload,
});
export const createLectureSuccess = (payload) => ({
  type: CREATE_LECTURE_SUCCESS,
  payload,
});
export const createLectureFaild = () => ({
  type: CREATE_LECTURE_FAILED,
});

export const deleteLectureRequest = (payload) => ({
  type: DELETE_LECTURE_REQUEST,
  payload,
});

export const deleteLectureSuccess = (payload) => ({
  type: DELETE_LECTURE_SUCCESS,
  payload,
});

export const deleteLectureFaild = () => ({
  type: DELETE_LECTURE_FAILED,
});

export const updateCurrentCourse = (payload) => ({
  type: UPDATE_CURRENT_COURSE,
  payload,
});

export const updateCurrentSection = (payload) => ({
  type: UPDATE_CURRENT_SECTION,
  payload,
});

export const prepareCreateCourse = () => ({
  type: PREPARE_CREATE_COURSE,
});

export const validateRequestFailed = (payload) => ({
  type: VALIDATE_FAILED,
  payload,
});

export const validateRequestSuccess = () => ({
  type: VALIDATE_SUCCESS,
});

export const finishCreate = () => ({
  type: FINNISH_CREATE,
});

export const beginSaveDraft = () => ({
  type: BEGIN_SAVEDRAFT,
});
export const endSaveDraft = () => ({
  type: END_SAVEDRAFT,
});

export const beginSubmit = () => ({
  type: BEGIN_SUBMIT,
});
export const endSubmit = () => ({
  type: END_SUBMIT,
});

export const resetState = () => ({
  type: RESET_PROCESS_STATE,
});

export const vewDetailCourse = () => ({
  type: RESET_PROCESS_STATE,
});
export const updateLectureRequest = (payload) => {
  return {
    type: UPDATE_LECTURE_REQUEST,
    payload
  }
}
export const updateLectureSuccess = (payload) => {
  return {
    type: UPDATE_LECTURE_SUCCESS,
    payload
  }
}
export const updateLectureFailed = (payload) => {
  return {
    type: UPDATE_LECTURE_FAILED,
    payload
  }
}
