import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILD,
  OPEN_NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATION_REQUEST,
  GET_UNREAD_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  MARK_READ_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION,
} from './constants';

export const addNotifications = (payload) => ({
  type: ADD_NOTIFICATION,
  payload,
});

export const getNotificationsRequest = (payload) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload,
});

export const getNotificationsSuccess = (payload) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsFaild = () => ({
  type: GET_NOTIFICATIONS_FAILD,
});

export const openNotifications = () => ({
  type: OPEN_NOTIFICATIONS,
});
export const closeNotifications = () => ({
  type: CLOSE_NOTIFICATIONS,
});

export const getUnreadNotificationRequest = () => ({
  type: GET_UNREAD_NOTIFICATION_REQUEST,
});

export const getUnreadNotificationSuccess = (payload) => ({
  type: GET_UNREAD_NOTIFICATION_SUCCESS,
  payload,
});

export const updateNotificationRequest = (payload, callback) => ({
  type: UPDATE_NOTIFICATION_REQUEST,
  payload,
  callback,
});

export const updateNotificationSuccess = (payload) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload,
});

export const getMarkAllReadNotificationSuccess = () => ({
  type: MARK_READ_NOTIFICATION_SUCCESS,
});
