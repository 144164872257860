import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { APIClient, setAuthorization } from '../../helpers/apiClient';

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  UPDATE_USER,
  OTP_REQUEST,
  RESEND_CODE,
  SEND_CODE_RESET_PW_REQUEST,
  EMAIL_USER_FORGET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  VERIFY_CODE_TYPE,
  GET_IP_ADDRESS_REQUEST,
  URL_IP_REQUEST,
  UPLOAD_AVATAR_REQUEST,
  KEY_AUTH_USER_STORAGE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_LANGUAGE,
  DELETE_ACCOUNT_REQUEST,
} from './constants';

import {
  loginUserSuccess,
  registerUserSuccess,
  forgetPasswordSuccess,
  apiError,
  updateUserSuccess,
  sendCodeResetPwSuccess,
  sendCodeResetPwFailed,
  verifyResetPwSuccess,
  getIpAddressFailed,
  getIpAddressSuccess,
  uploadAvatarFailed,
  uploadAvatarSuccess,
  updateUserProfileSuccess,
  resendCodeFailed,
  resetPasswordFailed,
  updateUserProfileFail,
} from './actions';
import { getAccessToken } from '../../helpers/user';
import { getLocalStorage, setLocalStorage } from 'src/helpers';
import { LANGUAGE } from 'src/pages/Library/constants';
import config from 'src/config';
import { setInitializedData } from '../masterData/actions';

/**
 * Sets the session
 * @param {*} user
 */

const create = new APIClient().create;
const update = new APIClient().update;
const get = new APIClient().get;
const deleteApi = new APIClient().delete;

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, history, device_id } }) {
  try {
    const response = yield call(create, '/api/auth/login', {
      email: username,
      password: password,
      device_id,
    });

    if (response.data.user.role === 'TEACHER') {
      localStorage.setItem(
        config.STORAGE_KEY_USER,
        JSON.stringify(response.data)
      );

      yield put(setInitializedData(false));
      yield put(loginUserSuccess(response.data));
      history.push('/profile');
    } else {
      localStorage.removeItem(config.STORAGE_KEY_USER); // remove token in localStorage in browser
      yield put(apiError('Not permission'));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Logout the user
 * @param {*} param
 */
function* logout({ payload: { history } }) {
  try {
    let token = JSON.parse(localStorage.getItem(config.STORAGE_KEY_USER)).token;
    setAuthorization(token); // add token in header
    yield call(create, '/api/auth/logout', null);
    localStorage.removeItem(config.STORAGE_KEY_USER); // remove token in localStorage in browser
    history.push('/login');
  } catch (error) {
    localStorage.removeItem(config.STORAGE_KEY_USER);
    history.push('/login');
  }
}

/**
 * Register the user
 */
function* register(action) {
  const { user, cb } = action.payload;
  try {
    if (!user.gender) {
      delete user.gender;
    }
    const dataUser = {
      ...user,
      name: user.username,
      birth_day: user.birthday,
    };
    const response = yield call(create, '/api/auth/register', dataUser);
    yield put(registerUserSuccess(response));
    cb();
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
  try {
    const response = yield call(create, '/forget-pwd', { email });
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Update the user
 */
function* updateUser({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, `/api/users/${payload.id}`, {
      name: payload.name,
    });
    let currentUser = JSON.parse(localStorage.getItem(config.STORAGE_KEY_USER));
    localStorage.setItem(
      config.STORAGE_KEY_USER,
      JSON.stringify({
        ...currentUser,
        user: response.data,
      })
    );
    yield put(updateUserSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* otpRequestMid(action) {
  const { code, email, cb } = action.payload;
  try {
    const verifyCodeType = JSON.parse(localStorage.getItem(VERIFY_CODE_TYPE));
    if (verifyCodeType === FORGET_PASSWORD) {
      const data = {
        code,
        email,
      };
      const response = yield call(update, '/api/password-resets/verify', data);
      const verifyCode = response.data.code;
      yield put(verifyResetPwSuccess(verifyCode));
      cb(response, '');
      return;
    }
    if (verifyCodeType === REGISTER_USER) {
      const data = {
        verification_code: code,
        email,
      };
      const response = yield call(update, '/api/auth/email-verify', data);
      cb(response, '');
      return;
    }
  } catch (error) {
    cb('', error);
    console.log(error);
  }
}

function* resendCode(action) {
  const { email, cb } = action.payload;
  try {
    const response = yield call(update, 'api/auth/resend-code', { email });
    cb(response, '');
  } catch (error) {
    cb('', error);
    yield put(resendCodeFailed(error));
  }
}

function* sendCodeResetPwMid(action) {
  const { email, cb } = action.payload;
  try {
    const res = yield call(create, '/api/password-resets', { email });
    localStorage.setItem(EMAIL_USER_FORGET_PASSWORD, JSON.stringify(email));
    yield put(sendCodeResetPwSuccess(res));
    cb();
  } catch (error) {
    yield put(sendCodeResetPwFailed(error));
    cb(error);
    yield put(apiError(error));
  }
}

function* resetPasswordRequest(action) {
  const { data, cb } = action.payload;
  const { email, code, password, confirmPassword } = data;
  try {
    const data = {
      email,
      code,
      password,
      password_confirmation: confirmPassword,
    };
    yield call(update, '/api/password-resets/change-password', data);
    cb();
  } catch (error) {
    cb(error);
    yield put(resetPasswordFailed(error));
  }
}

function* getIpAddressRequest() {
  try {
    const response = yield call(get, URL_IP_REQUEST);
    yield put(getIpAddressSuccess(response?.ip));
  } catch (error) {
    yield put(getIpAddressFailed());
  }
}

function* uploadAvatarRequest(action) {
  const formData = new FormData();
  const { fileUpload, cb } = action.payload;
  try {
    setAuthorization(getAccessToken());
    formData.append('avatar', fileUpload);
    setAuthorization(getAccessToken());
    const response = yield call(create, '/api/users/change-avatar', formData, {
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        'Content-Type': 'multipart/form-data',
      },
    });
    const imgUrl = response?.data;
    const userLocal = getLocalStorage(KEY_AUTH_USER_STORAGE);
    const data = {
      ...userLocal,
      user: {
        ...userLocal.user,
        thumbnail: {
          ...userLocal?.user?.thumbnail,
          url: imgUrl,
        },
      },
    };
    setLocalStorage(KEY_AUTH_USER_STORAGE, data);
    yield put(uploadAvatarSuccess(data));
    cb();
  } catch (error) {
    cb(error);
    yield put(uploadAvatarFailed(error));
  }
}

function* updateUserProfileRequest(action) {
  const { data, cb } = action.payload;
  try {
    setAuthorization(getAccessToken());
    const dataUser = {
      name: data.username,
      birth_day: data.birthday,
      gender: data.gender,
    };
    const response = yield call(update, `/api/users/${data.id}`, dataUser);
    const userLocal = getLocalStorage(KEY_AUTH_USER_STORAGE);
    setLocalStorage(KEY_AUTH_USER_STORAGE, {
      ...userLocal,
      user: response?.data,
    });
    yield put(updateUserProfileSuccess(response?.data));
    cb();
  } catch (error) {
    cb(error);
    yield put(updateUserProfileFail(error));
  }
}

function* updateLanguageRequest(action) {
  const { data, cb } = action.payload;
  try {
    setAuthorization(getAccessToken());
    const language = data.language || LANGUAGE.EN;
    const response = yield call(update, `/api/users/${data.id}`, { language });
    const userLocal = getLocalStorage(KEY_AUTH_USER_STORAGE);
    setLocalStorage(KEY_AUTH_USER_STORAGE, {
      ...userLocal,
      user: response?.data,
    });
    yield put(updateUserProfileSuccess(response?.data));
    cb();
  } catch (error) {
    console.log(error);
  }
}

function* deleteAccountRequestMid(action) {
  const { cb } = action.payload;
  try {
    setAuthorization(getAccessToken());
    yield call(deleteApi, `/api/users/request-delete`);
    cb();
  } catch (error) {
    cb(error);
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchOtpRequestMid() {
  yield takeLatest(OTP_REQUEST, otpRequestMid);
}

export function* watchResendCode() {
  yield takeLatest(RESEND_CODE, resendCode);
}

export function* watchSendCodeResetPw() {
  yield takeLatest(SEND_CODE_RESET_PW_REQUEST, sendCodeResetPwMid);
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordRequest);
}

export function* watchGetIpAddress() {
  yield takeLatest(GET_IP_ADDRESS_REQUEST, getIpAddressRequest);
}

export function* watchUploadAvatar() {
  yield takeLatest(UPLOAD_AVATAR_REQUEST, uploadAvatarRequest);
}

export function* watchUpdateUserProfile() {
  yield takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserProfileRequest);
}

export function* watchUpdateLanguage() {
  yield takeLatest(UPDATE_LANGUAGE, updateLanguageRequest);
}

export function* watchDeleteAccount() {
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccountRequestMid);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchUpdateUser),
    fork(watchOtpRequestMid),
    fork(watchResendCode),
    fork(watchSendCodeResetPw),
    fork(watchResetPassword),
    fork(watchGetIpAddress),
    fork(watchUploadAvatar),
    fork(watchUpdateUserProfile),
    fork(watchUpdateLanguage),
    fork(watchDeleteAccount),
  ]);
}

export default authSaga;
