export const GET_LIST_COURSE_REQUEST = 'GET_LIST_COURSE_REQUEST';
export const GET_LIST_COURSE_SUCCESS = 'GET_LIST_COURSE_SUCCESS';
export const GET_LIST_COURSE_FAILED = 'GET_LIST_COURSE_FAILED';

export const GET_COURSE_REQUEST = 'GET_COURSE_REQUEST';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_FAILED = 'GET_COURSE_FAILED';

export const GET_LIST_SECTION_REQUEST = 'GET_LIST_SECTION_REQUEST';
export const GET_LIST_SECTION_SUCCESS = 'GET_LIST_SECTION_SUCCESS';
export const GET_LIST_SECTION_FAILED = 'GET_LIST_SECTION_FAILED';

export const GET_LIST_LECTURE_REQUEST = 'GET_LIST_LECTURE_REQUEST';
export const GET_LIST_LECTURE_SUCCESS = 'GET_LIST_LECTURE_SUCCESS';
export const GET_LIST_LECTURE_FAILED = 'GET_LIST_LECTURE_FAILED';

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILED = 'CREATE_COURSE_FAILED';

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAILED = 'UPDATE_COURSE_FAILED';

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILED = 'DELETE_COURSE_FAILED';

export const GET_SECTION_REQUEST = 'GET_SECTION_REQUEST';
export const GET_SECTION_SUCCESS = 'GET_SECTION_SUCCESS';
export const GET_SECTION_FAILED = 'GET_SECTION_FAILED';

export const CREATE_SECTION_REQUEST = 'CREATE_SECTION_REQUEST';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAILED = 'CREATE_SECTION_FAILED';

export const UPDATE_SECTION_REQUEST = 'UPDATE_SECTION_REQUEST';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAILED = 'UPDATE_SECTION_FAILED';

export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILED = 'DELETE_SECTION_FAILED';

export const CREATE_LECTURE_REQUEST = 'CREATE_LECTURE_REQUEST';
export const CREATE_LECTURE_SUCCESS = 'CREATE_LECTURE_SUCCESS';
export const CREATE_LECTURE_FAILED = 'CREATE_LECTURE_FAILED';

export const UPDATE_LECTURE_REQUEST = 'UPDATE_LECTURE_REQUEST';
export const UPDATE_LECTURE_SUCCESS = 'UPDATE_LECTURE_SUCCESS';
export const UPDATE_LECTURE_FAILED = 'UPDATE_LECTURE_FAILED';

export const DELETE_LECTURE_REQUEST = 'DELETE_LECTURE_REQUEST';
export const DELETE_LECTURE_SUCCESS = 'DELETE_LECTURE_SUCCESS';
export const DELETE_LECTURE_FAILED = 'DELETE_LECTURE_FAILED';

export const UPDATE_CURRENT_COURSE = 'UPDATE_CURRENT_COURSE';
export const UPDATE_CURRENT_SECTION = 'UPDATE_CURRENT_SECTION';

export const PREPARE_CREATE_COURSE = 'PREPARE_CREATE_COURSE';
export const VALIDATE_FAILED = 'VALIDATE_FAILED';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const FINNISH_CREATE = 'FINNISH_CREATE';
export const RESET_PROCESS_STATE = 'RESET_PROCESS_STATE';
export const BEGIN_SAVEDRAFT = 'BEGIN_SAVEDRAFT';
export const END_SAVEDRAFT = 'END_SAVEDRAFT';
export const BEGIN_SUBMIT = 'BEGIN_SUBMIT';
export const END_SUBMIT = 'END_SUBMIT';
export const VIEW_DETAIL = 'VIEW_DETAIL';
