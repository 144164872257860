import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { getAccessToken } from 'src/helpers/user';

const apiClient = new APIClient();
const transactionService = {
  getTransactions: (payload) => {
    setAuthorization(getAccessToken());
    const params = Object.entries(payload)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    return apiClient.get('api/teachers/course-bought-transaction', { params });
  },
};
export { transactionService };
