import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import {
  CREATE_QUESTION_REQUEST,
  DELETE_QUESTION_REQUEST,
  FILTER_QUESTIONS_REQUEST,
  GET_QUESTIONS_DETAIL_REQUEST,
  GET_QUESTIONS_REQUEST,
  QUESTIONS_FAVORITE_REQUEST,
  QUESTIONS_UNLOCK_REQUEST,
  UPDATE_QUESTION_REQUEST,
} from './constants';

import * as actions from './actions';

const get = new APIClient().get;
const post = new APIClient().create;
const update = new APIClient().update;
const deleteMethod = new APIClient().delete;

function* getQuestionsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const { page, keyword, user_id } = payload;
    const url = keyword
      ? `api/questions?page=${page}&user_id=${user_id}&keyword=${keyword}`
      : `api/questions?page=${page}&user_id=${user_id}`;
    const response = yield call(get, url);
    yield put(actions.getQuestionsSuccess(response));
  } catch (error) {}
}

function* getQuestionDetailRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, `api/questions/${payload}`);
    yield put(actions.getQuestionDetailSuccess(response.data));
  } catch (error) {}
}

function* filterQuestionsRequest(action) {
  const data = action.payload;
  const formData = new FormData();
  try {
    if (data?.order_by) {
      formData.append('order_by', data.order_by);
    }
    if (data?.topic_id) {
      formData.append('topic_id', data.topic_id);
    }
    if (data?.is_free !== null) {
      formData.append('is_free', data.is_free);
    }
    if (data?.label_ids.length) {
      formData.append('is_free', data.is_free);
    }
    formData.append('name', 'abc');
    const res = yield call(post, '/api/questions', formData);
    yield put(actions.filterQuestionsSuccess(res.data));
  } catch (error) {
    yield put(actions.filterQuestionsFailed(error));
  }
}

function* questionUnlockRequest({ payload, callback }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, 'api/questions/' + payload + '/unlock');

    callback(response.data.status);
  } catch (error) {
    callback(false);
  }
}

function* questionFavoriteRequest({ payload, callback }) {
  try {
    setAuthorization(getAccessToken());
    yield call(post, 'api/users/question', payload);

    callback && callback(true);
  } catch (error) {
    callback && callback(false);
  }
}

function* createQuestionsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(post, 'api/questions', payload);
    yield put(actions.createQuestionSuccess(response.data));
  } catch (error) {
    yield put(actions.createQuestionFinish(error));
  }
}

function* updateQuestionsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield post(
      `api/questions/${payload.id}?_method=PUT`,
      payload.formData
    );
    yield put(actions.updateQuestionSuccess(response.data));
  } catch (error) {
    yield put(actions.updateQuestionFailed(error));
  }
}

function* deleteQuestionsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(deleteMethod, `api/questions/${payload.id}`);
    yield put(actions.deleteQuestionSuccess(response));
  } catch (error) {
    yield put(actions.deleteQuestionFailed());
  }
}

export function* watchUpdateQuestionsRequest() {
  yield takeEvery(UPDATE_QUESTION_REQUEST, updateQuestionsRequest);
}

export default function* questionsSaga() {
  yield all([
    takeEvery(GET_QUESTIONS_REQUEST, getQuestionsRequest),
    takeEvery(GET_QUESTIONS_DETAIL_REQUEST, getQuestionDetailRequest),
    takeEvery(FILTER_QUESTIONS_REQUEST, filterQuestionsRequest),
    takeEvery(QUESTIONS_UNLOCK_REQUEST, questionUnlockRequest),
    takeEvery(QUESTIONS_FAVORITE_REQUEST, questionFavoriteRequest),
    fork(watchUpdateQuestionsRequest),
    takeEvery(CREATE_QUESTION_REQUEST, createQuestionsRequest),
    takeEvery(DELETE_QUESTION_REQUEST, deleteQuestionsRequest),
  ]);
}
