import React from 'react';

const MenuItem = ({ title, classNames, onClick }) => {
  return (
    <li className={classNames} onClick={onClick}>
      {title}
    </li>
  );
};

export default MenuItem;
